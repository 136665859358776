import { parseISO, format } from "date-fns";

export const GetUTCDate = (date: Date): Date => {
  const makeSureDate = new Date(date);

  const isoString = makeSureDate.toISOString();
  const parsedDate = parseISO(isoString);

  return parsedDate;
};

export const GetDateString = (date: Date): string => {
  const makeSureDate = new Date(date);
  const result = format(makeSureDate, "yyyy-MM-dd");

  return result;
};

export const getMinutesBetweenDates = (startDate: Date, endDate: Date) => {
  const makeSureStartDate = new Date(startDate);
  const makeSureEndDate = new Date(endDate);

  const diff = Math.abs(
    makeSureStartDate.getTime() - makeSureEndDate.getTime()
  );
  const minutes = Math.floor(diff / 1000 / 60);
  //const diffMs = (startDate - endDate)
  //const diff = endDate.getTime() - startDate.getTime();
  return minutes;
};
