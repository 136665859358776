export const UpDownVoterPhrases = {
  en: {
    haveUpVotedAlternative: "You think this is a good alternative!",
    haveUpVotedFeature: "You think this is an important feature!",
    haveUpVotedNews: "You found this interesting",
    haveDownVotedAlternative: "You do not think is a good alternative!",
    haveDownVotedFeature: "You do not think this is an important feature!",
    haveDownVotedNews: "You did not find this interesting.",
    removeVote: "Remove vote",
    removeVoteTitle: "I've changed my mind, remove this vote.",
    defaultTextVoteNews: "Did you find this interesting?",
    defaultTextVoteAlternative: "Is this is a good alternative?",
    upVoteTextNews: "I find this interesting!",
    downVoteTextNews: "I did not care about this.",
  },
};
