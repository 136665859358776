import React from "react";

type ContentWithAlignedIconWrapperProps = {
  children: any;
};

const ContentWithAlignedIconWrapper = (
  props: ContentWithAlignedIconWrapperProps
) => {
  return (
    <span style={{ display: "inline-flex", alignItems: "center" }}>
      {props.children}
    </span>
  );
};

export default ContentWithAlignedIconWrapper;
