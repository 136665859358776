/**
 * Pluralize words. There are npm packages for this
 * but rolling our own helper function keeps the bundle
 * size smaller. We only need to support a few words:
 * likes, alternatives, users, apps, recommendations,
 * comments etc etc. Quite easy to pluralize.
 *
 * More reading:
 * https://www.grammarly.com/blog/plural-nouns/
 */
export const plural = (singular: string, count: number): string => {
  if (count !== 1) {
    if (singular.slice(-1) === "y") {
      return singular.slice(0, singular.length - 1) + "ies";
    }
    if (singular.slice(-2) === "af") {
      return singular.slice(0, singular.length - 2) + "aves";
    }

    if (singular.slice(-4) === "ware") {
      return singular;
    }
  }

  if (singular === "doesn't") {
    if (count !== 1) {
      return "don't";
    } else {
      return singular;
    }
  }

  return count === 1 ? singular : singular + "s";
};
