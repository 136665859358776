import ProfilePic from "~/c/ProfilePic";
import { CommonUser } from "~/typings/types";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./ListOfUsers.module.scss";
import { plural } from "~/helpers/plurals";
import UserProfileLink from "~/c/UserProfileLink";

interface ListOfUserVotesProps {
  users: CommonUser[];
  totalOpinions: number;
  titleText?: string;
  size?: "large" | "small";
  type: "feature" | "app" | "news" | "app-no-meta";
  userIncluded?: boolean;
  testId?: string;
}

export const ListOfUsers = (props: ListOfUserVotesProps) => {
  const {
    users,
    totalOpinions,
    titleText,
    size = "small",
    type = "feature",
    userIncluded,
    testId,
  } = props;

  const showMetaText = totalOpinions && totalOpinions > 0;
  const showNumberOfUsers = 4;
  const showUsers = userIncluded || totalOpinions > 0;

  let metaText = <></>;
  const metaPhrase =
    type == "news" ? "found this interesting" : "like this app";

  if ((type !== "feature" && type !== "app-no-meta") || userIncluded) {
    metaText = (
      <MetaText
        users={users}
        totalOpinions={totalOpinions}
        showNumberOfUsers={showNumberOfUsers}
        metaPhrase={metaPhrase}
        userIncluded={userIncluded}
      />
    );
  } else if (totalOpinions - showNumberOfUsers > 0) {
    if (type === "app-no-meta") {
      metaText = <></>;
    } else {
      metaText = <>+{totalOpinions - showNumberOfUsers}</>;
    }
  }

  return (
    <>
      {showUsers ? (
        <div
          data-testid={testId ? testId : `profile-pictures`}
          className={`${styles.profilePictures} ${styles[size]}`}
        >
          {users &&
            users.slice(0, showNumberOfUsers).map((user, i) => {
              const fileName = user.image && user.image.fileName;

              if (user.nick == "Guest") return null;

              return (
                <span
                  title={
                    titleText ? `${user.nick} ${titleText}` : `${user.nick}`
                  }
                  className={styles.imgSpan}
                  key={i}
                >
                  <UserProfileLink
                    customContent={
                      <ProfilePic
                        imgObject={user.image}
                        nick={user.nick}
                        width={size === "large" ? 40 : 20}
                        height={size === "large" ? 40 : 20}
                        renderWidth={size === "large" ? 28 : 20}
                        renderHeight={size === "large" ? 28 : 20}
                        style={
                          fileName !== ""
                            ? {
                                borderRadius: 100,
                              }
                            : {
                                borderRadius: 100,
                                position: "relative",
                                top: "-7px",
                              }
                        }
                      />
                    }
                    userId={user.userId}
                    nick={user.nick}
                    urlNick={user.urlNick}
                  ></UserProfileLink>
                </span>
              );
            })}
          {/* <span
            className={styles.imgSpan}
            style={{
              backgroundColor: "var(--gray100)",
              border: "1px solid var(--gray250)",
              borderRadius: "100%",
              zIndex: 10,
              fontSize: "0.8rem",
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
            }}
          >
            {totalOpinions}
          </span> */}
          {showMetaText && (
            <div className={`meta ${styles.metaText}`}>{metaText}</div>
          )}
        </div>
      ) : (
        <>
          {type === "app" && (
            <div className={`meta ${styles.metaText}`}>
              Like the app if you find it interesting
            </div>
          )}
        </>
      )}
    </>
  );
};

interface MetaTextProps {
  users: any[]; // Replace with your actual User type
  totalOpinions: number;
  showNumberOfUsers: number;
  metaPhrase: string;
  userIncluded: boolean;
}

const MetaText: React.FC<MetaTextProps> = ({
  users,
  totalOpinions,
  showNumberOfUsers,
  metaPhrase,
  userIncluded,
}) => {
  let mentionUser = users && users.length > 0 && (
    <UserProfileLink
      userId={users[0].userId}
      nick={users[0].nick}
      urlNick={users[0].urlNick}
    ></UserProfileLink>
  );

  if (userIncluded) {
    mentionUser = <>You</>;
  }

  let metaText;

  if (totalOpinions - showNumberOfUsers > 10) {
    if (userIncluded) {
      metaText = (
        <>
          {mentionUser} {metaPhrase}
        </>
      );
    } else {
      metaText = (
        <>
          {totalOpinions} {plural("user", totalOpinions)} {metaPhrase}
        </>
      );
    }
  } else if (totalOpinions > 1) {
    metaText = (
      <>
        {mentionUser} {metaPhrase}
      </>
    );
  } else {
    metaText = (
      <>
        {mentionUser} {metaPhrase}
      </>
    );
  }

  return <>{metaText}</>;
};
