/* eslint-disable import/no-anonymous-default-export */
export default {
  smUp: "min-width: 576px",
  mdUp: "min-width: 768px",
  lgUp: "min-width: 992px",
  xlUp: "min-width: 1200px",
  // Should these have +- 1px?
  smDown: "max-width: 576px",
  mdDown: "max-width: 768px",
};
