export const AppPagePhrases = {
  en: {
    tabAbout: "Info, reviews & more",
    errorLike: "Something went wrong. Your like might not have been saved.",
    errorRemoveAlternative:
      "Something went wrong. The alternative might not have been removed.",
    errorLoadComments: "Something went wrong. Couldn't load more comments.",
    linksAndDownloads: "Official Links",
    noLinks: "There are no links for this app, or they've been disabled.",
    pendingReportMessage:
      "This app has a pending report. Click here to manage it.",
    pendingChangeMessage:
      "This app has a pending change. Click here to manage it.",
    hiddenAppMessage:
      "This app is hidden. Click here to open the admin view to change it.",
    notApprovedRegularUser:
      "Your submission hasn’t been approved yet. We usually approve apps within 24 hours on weekdays. Only you can see this app right now, so please do not share the link to it yet. You will get a notification when it's approved.",
    notApprovedAdmin:
      "This app is not approved yet. Click here to approve or decline this submission.",
    suggestFeatures: "Suggest and vote on features",
    postCommentOrReview: "Post comment/review",
    suggestAlternativesButtonMain: "Know any alternatives to {mainItem.name}?",
    suggestAlternativesButtonSubtext:
      "The world would be a slightly better place if you would suggest some",
    toastMessages: {
      defaultBodyMessage: "Thanks for making AlternativeTo a better site",
      editApprovedHeader: "Your changes have been saved",
      editWaitHeader: "We will approve your suggestions shortly",
      editWaitBody: `An admin will take a look at your suggestions and hopefully approve them soon. Thank you!`,
      addApprovedHeader: "{appName} has been added to the site",
      addWaitHeader: "{appName} will be approved shortly",
      addWaitBody: `An admin will take a look and hopefully approve it soon. Thank you!`,
      exactMatchHeader: "We found an exact match for {searchPhrase}",
      exactMatchBody:
        "If you would like to search for '{searchPhrase}' instead, click here",
    },
    filterBar: {
      hiddenAppsTooltip:
        "{count} apps are hidden since they may have legal issues when used or are NSFW. Click here to show them.",
      activeFilter:
        "You have an active filter. Open the filter menu below to make changes.",
      legalWarningCheckbox: "Hide apps with legal warning",
      nsfwWarningCheckbox: "Hide apps with NSFW warning",
    },
    appPageMenu: {
      editApp: "Edit / Update Information",
      editTaglines: "Edit taglines",
      suggestAlternatives: "Suggest alternatives",
      reportSomethingWrong: "Report something wrong",
    },
    aboutSummary: {
      summaryIntro:
        "Our users have written {commentCount} comments and reviews about {appName}, and it has gotten {likes} likes",
      developedBy: "Developed by",
      averageRating: "Average rating of",
      alternativesListed: "alternatives listed",
      appsAsPlatformHeader: "Top {appName} apps (extensions / mods etc)",
      popularAlternatives: "Popular alternatives",
      gitHubRepository: "GitHub repository",
    },
    comments: {
      expandComment: "Show entire comment",
      expandCommentWithReplies: "Show entire comment and {replies}",
      agreeWithComment: "I totally agree!",
      disagreeWithComment: "I do not agree with this comment",
      haveVoted_agreeWithComment: "You agree on this comment",
      haveVoted_disagreeWithComment: "You do not agree on this comment",
      copyDirectLink: "Copy a direct link to this comment to your clipboard",
    },
    alternativeList: {
      opinionStatsAlmostEveryone:
        "**Almost everyone** thinks {appName} **is a great** {mainItemName} alternative.",
      opinionStatsMostUsers:
        "**Most users** think {appName} **is a great** {mainItemName} alternative.",
      opinionStatsSomeUsers:
        "**Some users** think {appName} **is a great** {mainItemName} alternative, some don't.",
      opinionStatsDisputed:
        "This alternative is **disputed**. {appName} is a legitimate {mainItemName} alternative, but it might have issues that some users think are important.",
      opinionStatsTitle:
        "Stats based on likes and votes from our users. See comments below for details.",
      noComments:
        "No comments about {alternative} VS {mainItemName}, maybe you want to be first?",
    },
    features: {
      allFeatures: "All features",
    },
  },
};
