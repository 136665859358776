import React, { forwardRef } from "react";
import styles from "./Checkbox.module.scss";
import classNames from "classnames";
// interface CheckboxProps extends FormControlDefault {
//   defaultChecked: boolean;
//   value?: any;
//   name: string;
//   register?: any;
//   shortDescription?: string;
//   onChange?(text, checked): any;
// }

interface CheckboxProps
  extends React.PropsWithoutRef<JSX.IntrinsicElements["input"]> {
  label: string;
  shortDescription?: string;
}

// eslint-disable-next-line react/display-name
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, id, shortDescription, ...props }, ref) => {
    return (
      <>
        <div className={classNames("form-check", styles.formCheck)}>
          <input
            ref={ref}
            className={classNames("form-check-input", styles.formCheckInput)}
            type="checkbox"
            id={id}
            {...props}
          />
          <label
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
            className={classNames("form-check-label", styles.formCheckLabel)}
            htmlFor={id}
          >
            {label} <span className="meta">{shortDescription}</span>
          </label>
        </div>
      </>
    );
  },
);

export default Checkbox;
