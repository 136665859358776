import React from "react";
import Heading from "~/components/Heading";
import Button from "~/components/Button";
import Spacer from "~/components/Spacer";
import FeatureAutocomplete from "~/components/FeatureAutocomplete";
import { UnsavedFeature, FeatureWithUserInfo } from "~/typings/types";
import { DataCollectorChildProps } from "~/components/DataCollector/types";

import { RootContext } from "~/components/Contexts/RootContext";
import { DataCollectorPhrases } from "~/helpers/phrases/data-collector";
import LoadingSkeleton from "~/components/Common/LoadingSkeleton";
import { callAPIClientSide } from "~/helpers/api";
import { FeatureListItem } from "./FeatureListItem";
import styles from "./Features.module.scss";

type Props = DataCollectorChildProps;

const Features: React.FC<Props> = ({ item, done }) => {
  const [state, setState] = React.useState({
    selectedFeatures: [],
    requestInProcess: false,
    featureDetails: [],
    appTypeHeader: false,
    featureHeader: false,
    isLoading: true,
  });

  const context = React.useContext(RootContext);

  React.useEffect(() => {
    callAPIClientSide(`/items/${item.id}/existing-and-suggested-tags`)
      .then((res) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
          featureDetails: res.items,
          selectedFeatures: res.items.filter(
            (feature) => feature.likedByCurrentUser === true,
          ),
        }));
      })
      .catch((err) => {
        context.setError("Error loading features " + "(" + err.message + ")");
      });
  }, [item.id]);

  const getExistingFeatures = (): FeatureWithUserInfo[] => {
    return state.featureDetails && state.featureDetails;
  };

  const addFeature = (feature: UnsavedFeature) => {
    if (
      state.selectedFeatures.find(
        (selectedFeature) => selectedFeature.groupName === feature.groupName,
      )
    ) {
      return false;
    }
    setState((prev) => ({
      ...prev,
      selectedFeatures: [...prev.selectedFeatures, feature],
    }));
  };

  const removeFeature = (featureId: string) => {
    setState((prev) => ({
      ...prev,
      selectedFeatures: [
        ...prev.selectedFeatures.filter(
          (selectedFeature) => selectedFeature.groupName !== featureId,
        ),
      ],
    }));
  };

  const toggleFeature = (feature: UnsavedFeature | FeatureWithUserInfo) => {
    if (isSelectedByUser(feature.groupName)) {
      removeFeature(feature.groupName);
    } else {
      addFeature(feature);
    }
  };

  const sendData = () => {
    setState((prev) => ({ ...prev, requestInProcess: true }));

    const body = state.selectedFeatures.map((val) => ({
      name: val.name,
      urlName: val.groupName,
    }));

    callAPIClientSide(
      `/items/${item.id}/features/`,
      null,
      "POST",
      context.userId,
      body,
      context.recaptchaToken,
    )
      .then(() => {
        setState((prev) => ({ ...prev, requestInProcess: false }));
        done();
      })
      .catch((err) => {
        context.setError("Error saving feature(s) " + "(" + err.message + ")");
        done();
      });
  };

  const isSelectedByUser = (featureName: string) => {
    const test = state.selectedFeatures.find((selectedFeature) => {
      return selectedFeature.groupName === featureName;
    });
    return !!test;
  };

  const { requestInProcess, selectedFeatures } = state;

  const appName = item.name;

  const mergedFeatures: (FeatureWithUserInfo | UnsavedFeature)[] = [
    ...selectedFeatures.map((selectedFeature) => {
      const existingFeatureWithSameName = getExistingFeatures().find(
        (existingFeature) =>
          existingFeature.groupName === selectedFeature.groupName,
      );

      return {
        ...selectedFeature,
        likes:
          existingFeatureWithSameName && existingFeatureWithSameName.likes + 1,
      };
    }),
    ...getExistingFeatures()
      .filter((existingFeature) => !isSelectedByUser(existingFeature.groupName))
      .sort((a, b) => {
        if (a.appType === b.appType) {
          // Price is only important when cities are the same
          return b.likes - a.likes;
        }
        if (a.appType === undefined) return 1;
        if (b.appType === undefined) return -1;
      }),
  ];

  const renderFeatureList = () => {
    const appTypes = mergedFeatures.filter((x) => x.appType);
    const properties = mergedFeatures.filter((x) => x.type === "Property");
    const features = mergedFeatures.filter(
      (x) => !x.appType && x.type !== "Property",
    );

    return (
      <ul className={styles.featureList}>
        {appTypes.length > 0 && (
          <>
            <li className="meta">Application types</li>
            {appTypes.map((feature) => (
              <FeatureListItem
                key={feature.groupName}
                feature={feature}
                isSelectedByUser={isSelectedByUser(feature.groupName)}
                onChange={() => toggleFeature(feature)}
                itemName={item.name}
              />
            ))}
          </>
        )}

        {properties.length > 0 && (
          <>
            <li className="meta">Properties</li>
            {properties.map((feature) => (
              <FeatureListItem
                key={feature.groupName}
                feature={feature}
                isSelectedByUser={isSelectedByUser(feature.groupName)}
                onChange={() => toggleFeature(feature)}
                itemName={item.name}
              />
            ))}
          </>
        )}

        {features.length > 0 && (
          <>
            <li className="meta">Features</li>
            {features.map((feature) => (
              <FeatureListItem
                key={feature.groupName}
                feature={feature}
                isSelectedByUser={isSelectedByUser(feature.groupName)}
                onChange={() => toggleFeature(feature)}
                itemName={item.name}
              />
            ))}
          </>
        )}
      </ul>
    );
  };

  return (
    <div data-testid="dc-feature">
      <Heading element="h2">{DataCollectorPhrases.en.feature.header}</Heading>
      <p>
        Help others discover {appName} by adding and vote on properties,
        features and application types to the app.
      </p>
      <Spacer space={1} />

      <FeatureAutocomplete
        inputDisabled={false}
        onSubmit={(feature) => addFeature(feature)}
      />

      <p className="small meta">
        <>
          1-3 words, like{" "}
          <span className="feature-label">International calling</span> or{" "}
          <span className="feature-label">FTP support</span>
        </>
      </p>
      <Spacer space={1} />
      <p>
        {DataCollectorPhrases.en.feature.mostImportant.replace(
          "{appName}",
          appName,
        )}
      </p>

      {state.isLoading && <LoadingSkeleton repeat={5}></LoadingSkeleton>}
      <Spacer space={2} />

      {selectedFeatures && (
        <>
          {mergedFeatures.length > 0 ? (
            renderFeatureList()
          ) : (
            <span className="meta">
              This app has no features and no application types, please suggest
              something above.
            </span>
          )}
          <Spacer space={4} />
        </>
      )}

      <Spacer space={4} />
      <Button
        look="primary"
        fill={true}
        disabled={requestInProcess}
        onClick={() => sendData()}
      >
        {requestInProcess ? "Saving..." : "Save"}
      </Button>
    </div>
  );
};

export default Features;
