"use client";

import React, { useState, useContext } from "react";
import { ProfileUser, RootContextValues } from "~/typings/types";
import { Popover, ArrowContainer } from "react-tiny-popover";
import { RootContext } from "./Contexts/RootContext";
import { FaExternalLinkAlt } from "react-icons/fa";
import colors from "@/scss/colors.module.scss";
import dynamic from "next/dynamic";
import { callAPIClientSide } from "~/helpers/api";
import UserProfileRank from "~/c/UserProfileRank";

// Check with Vercel V0

const UserProfileMini = dynamic(() => import("./UserProfileMini"), {
  ssr: false,
});

type UserProfileLinkProps = {
  userId: string;
  nick: string;
  urlNick: string;
  showIfGuest?: boolean;
  fullName?: string;
  userRank?: number;
  customContent?: React.ReactNode;
  className?: string;
};

function getArrowStyle(position: string, arrowSize = 10) {
  switch (position) {
    case "left":
      return {
        right: 1,
        borderLeft: `${arrowSize}px solid white`,
        zIndex: 1,
      };
    case "right":
      return {
        left: 1,
        borderRight: `${arrowSize}px solid white`,
        zIndex: 1,
      };
    case "bottom":
      return {
        top: 1,
        borderBottom: `${arrowSize}px solid var(--mainBg)`,
        zIndex: 1,
      };
    default:
      return {
        bottom: 1,
        borderTop: `${arrowSize}px solid white`,
        zIndex: 1,
      };
  }
}

export default function UserProfileLink({
  userId,
  nick,
  urlNick,
  fullName,
  userRank,
  customContent,
  className,
}: UserProfileLinkProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [profileUser, setProfileUser] = useState<ProfileUser | null>(null);
  const rootContext = useContext<RootContextValues>(RootContext);

  const fetchUser = async () => {
    try {
      const json = await callAPIClientSide(`/users/${userId}/`);
      setProfileUser(json);
      setIsPopoverOpen(true);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      setIsPopoverOpen(false);
    }
  };

  if (nick === "Guest") {
    return <span className="text-meta">Guest</span>;
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["bottom", "left"]}
      padding={2}
      containerStyle={{ zIndex: "999" }}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          arrowSize={10}
          popoverRect={popoverRect}
          arrowColor={colors.gray250}
        >
          <ArrowContainer
            position={position}
            arrowSize={10}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={colors.mainBg}
            style={{
              padding: 0,
            }}
            arrowStyle={getArrowStyle(position, 10)}
          >
            {profileUser && (
              <UserProfileMini
                isLoggedIn={!!rootContext.user}
                profileUser={profileUser}
              />
            )}
          </ArrowContainer>
        </ArrowContainer>
      )}
    >
      {!customContent ? (
        <span className={className}>
          <span
            translate="no"
            className="profile-link fake-a inline-flex items-center"
            onClick={fetchUser}
          >
            {fullName || nick}
            {rootContext.isCrewAdmin && (
              <a
                className="text-[80%] pl-1 inline-flex items-center relative top-[0px]"
                href={`/user/${urlNick}/`}
              >
                <FaExternalLinkAlt size={10} />
              </a>
            )}
          </span>
          {userRank && (
            <span
              style={{ fontSize: "90%", marginLeft: "4px" }}
              className="meta-relative"
            >
              <UserProfileRank
                showIcon={true}
                showLevel={true}
                userRank={userRank}
              />
            </span>
          )}
        </span>
      ) : (
        <span
          translate="no"
          className="profile-link fake-a"
          style={{ marginBottom: "-2px" }}
          onClick={fetchUser}
        >
          {customContent}
        </span>
      )}
    </Popover>
  );
}
