import React, { FunctionComponent } from "react"; // we need this to make JSX compile

type SpacerProps = {
  space: number;
};

const Spacer: FunctionComponent<SpacerProps> = ({ space = 1 }: SpacerProps) => (
  <div className={`spacer-${space}`}></div>
);

export default Spacer;
