import React, { useContext } from "react";
import { FaLink } from "react-icons/fa";
import { AppPagePhrases } from "~/helpers/phrases/app-pages";
import { RootContextValues } from "~/typings/types";
import { RootContext } from "~/components/Contexts/RootContext";

type CopyDirectLinkProps = {
  toastBody: string;
  url: string;
  iconSize?: string;
};

const CopyDirectLink = (props: CopyDirectLinkProps) => {
  const { toastBody, url, iconSize } = props;

  const rootContext = useContext<RootContextValues>(RootContext);

  return (
    <FaLink
      size={iconSize || undefined}
      onClick={() => {
        rootContext.setInfo({
          autoClose: 3000,
          position: "bottom-center",
          body: toastBody,
        });
        navigator.clipboard.writeText(url);
      }}
      title={AppPagePhrases.en.comments.copyDirectLink}
      data-testid="copyDirectLink"
      className="transitionDefault"
      style={{ cursor: "pointer" }}
    ></FaLink>
  );
};
export default CopyDirectLink;
