import React, { useEffect, useContext, useRef } from "react";
import { DataCollectorChildProps } from "~/components/DataCollector/types";
import { AuthUser } from "~/typings/types";
import { RootContext } from "~/components/Contexts/RootContext";
import { callAPIClientSide } from "~/helpers/api";

type Props = DataCollectorChildProps;

const SilentAction: React.FC<Props> = ({ done, silentAction, finish }) => {
  const context = useContext(RootContext);
  const hasRunRef = useRef(false);

  useEffect(() => {
    const abortController = new AbortController();
    const user: AuthUser = context.user;
    const isLoggedIn = !!user;

    // Only run once when mounted
    if (hasRunRef.current) return;
    hasRunRef.current = true;

    if (
      silentAction &&
      (silentAction.type === "AlternativeCommentVote" ||
        silentAction.type === "FeatureVote" ||
        silentAction.type === "AlternativeVote" ||
        silentAction.type === "NewsVote")
    ) {
      callAPIClientSide(
        silentAction.url,
        null,
        "POST",
        context.userId,
        silentAction.body,
        context.recaptchaToken,
      )
        .then((res) => {
          if (res.id > 0) {
            done?.(res);
          } else {
            finish?.();
          }
        })
        .catch((err) => {
          console.debug("API error: " + err);
          context.setError("API error " + "(" + err.message + ")");
        });
    } else if (silentAction && silentAction.type === "Redirect") {
      if (isLoggedIn) {
        window.top.location.href = silentAction.url;
      } else {
        done?.();
      }
    }

    return () => {
      abortController.abort();
    };
  }, [silentAction]); // Dependencies still included for React rules of hooks

  return <div className={`wrapper initialized`}></div>;
};

export default SilentAction;
