import React, { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import Button from "~/c/Button";
import { RootContext } from "~/c/Contexts/RootContext";
import { DataCollectorChildProps } from "~/c/DataCollector/types";
import Checkbox from "~/c/Form/Basic/Checkbox";
import Heading from "~/c/Heading";
import Spacer from "~/c/Spacer";
import { callAPIClientSide, callAPIForSWR } from "~/helpers/api";
import { DataCollectorPhrases } from "~/helpers/phrases/data-collector";
import { CustomListCollection, RootContextValues } from "~/typings/types";

import styles from "./AddToList.module.scss";
import classNames from "classnames";
import LoadingSkeleton from "~/c/Common/LoadingSkeleton";
import Link from "next/link";
//interface AddToListProps extends DataCollectorChildProps {}

const AddToList = (props: DataCollectorChildProps) => {
  const { item, done } = props;

  const rootContext = useContext<RootContextValues>(RootContext);

  const [selectedLists, setSelectedLists] = useState<number[]>([]);

  const fetcher = (url) => callAPIForSWR(url);
  const { data: userLists, isValidating: loadingLists } =
    useSWR<CustomListCollection>(
      `/lists?userId=${rootContext.user.userId}&pageSize=50&sortColumn=updatedDate&sortDirection=desc`,
      fetcher,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      },
    );

  const { data: userListWithItem, isValidating: loadingSelectedLists } =
    useSWR<CustomListCollection>(
      `/lists?userId=${rootContext.user.userId}&itemId=${item.id}`,
      fetcher,
      {
        revalidateIfStale: true,
        revalidateOnFocus: true,
        revalidateOnReconnect: false,
      },
    );

  useEffect(() => {
    if (userListWithItem) {
      setSelectedLists(
        userListWithItem.items.map((l) => {
          return l.listId;
        }),
      );
    }
  }, [userListWithItem]);

  const toggleList = async (listId: number) => {
    // If existing we add the groupName as well, otherwise only name.
    const body = {
      listId: listId,
      itemId: item.id,
    };

    if (selectedLists.includes(listId)) {
      setSelectedLists(selectedLists.filter((l) => l !== listId));
    } else {
      setSelectedLists([...selectedLists, listId]);
    }

    await callAPIClientSide(
      `/lists/add-remove-item/`,
      null,
      "POST",
      rootContext.userId,
      body,
    );
  };

  return (
    <div data-testid="dc-add-to-list">
      <Heading element="h2">
        {DataCollectorPhrases.en.addToList.header.replace(
          "{item.name}",
          item.name,
        )}
      </Heading>
      <Spacer space={2} />

      {(loadingLists || loadingSelectedLists) && (
        <LoadingSkeleton repeat={5}></LoadingSkeleton>
      )}
      <p>
        Choose the lists to add this app to. If you need a new list or
        don&apos;t have any,{" "}
        <Link
          style={{
            fontWeight: 500,
          }}
          href={"/lists/create/"}
        >
          create one here.
        </Link>
      </p>
      <Spacer space={2} />
      <ul className={classNames(styles.lists)}>
        {!(loadingLists || loadingSelectedLists) &&
          userLists &&
          userLists.items.map((l) => {
            return (
              <>
                <li key={l.listId}>
                  <Checkbox
                    checked={selectedLists.includes(l.listId)}
                    onChange={() => toggleList(l.listId)}
                    label={l.title}
                  ></Checkbox>
                </li>
              </>
            );
          })}
      </ul>

      <Spacer space={4}></Spacer>
      <Button look="primary" fill={true} onClick={() => done()}>
        Done
      </Button>
    </div>
  );
};
export default AddToList;
