import {
  AuthUser,
  CommentEvent,
  CommonItem,
  ICommonComment,
  ICommonCommentReply,
  ItemMetaTag,
  PublishStatus,
} from "~/typings/types";
import { manualHtmlDecode, truncate } from "./stringHelpers";

export const stringToPublishStatusNumber = (str: CommentEvent): number => {
  if (str === "comment:approve") return 0;
  else if (str === "comment:hide") return 4;
  else if (str === "comment:delete") return 2;
  else throw Error("Wrong status code");
};

export const stringToPublishStatusType = (str: CommentEvent): PublishStatus => {
  if (str === "comment:approve") return "Approved";
  else if (str === "comment:hide") return "Hidden";
  else if (str === "comment:delete") return "Delete";
  else throw Error("Wrong status code");
};

export const GetApiErrorMessage = (text: string, err: any) => {
  let errorString = `Error ${text}: `;

  if (err.message) {
    errorString += `${err.message} `;
  }

  errorString += `${err.status} ${err.statusText}`;

  throw new Error(errorString);
};

export const getAppOgImage = (mainItem: CommonItem) => {
  const ogImage = mainItem.images.find((image) => image.type === "Screenshot");
  let ogImageData = null;

  if (mainItem.urlName === "spotify" || mainItem.urlName === "github") {
    return (ogImageData = [
      {
        url:
          "https://og.alternativeto.net/alternative/" +
          mainItem.urlName +
          ".png",
        width: 1200,
        height: 630,
        alt: mainItem.name,
      },
    ]);
  }

  if (ogImage) {
    const signedHiresScreenshot =
      ogImage.signedImages &&
      ogImage.signedImages.find(
        (x) => x.size === "1200x1200" || x.size === "2400x2400",
      );

    ogImageData = [
      {
        url: signedHiresScreenshot.signedURL,
        width: 800,
        height: 600,
        alt: mainItem.name + " Screenshot",
      },
    ];
  }

  return ogImageData;
};

const updateReplies = (
  replies: ICommonCommentReply[],
): ICommonCommentReply[] => {
  if (replies) {
    return replies.map((r) => ({
      ...r,
      publishStatus: "Approved",
    }));
  }
};

export const getGameSeriesDesc = (
  gameSeriesMetaTag: ItemMetaTag,
  mainItemName: string,
  subheading?: string,
) => {
  let desc = "";

  const encodedItemName = manualHtmlDecode(mainItemName);

  if (gameSeriesMetaTag) {
    if (gameSeriesMetaTag.description.includes(",")) {
      desc = `Represent entire series of ${encodedItemName} games including ${truncate(
        manualHtmlDecode(gameSeriesMetaTag.description),
        250,
        false,
        true,
      )}. `;
    } else {
      desc = `Represent entire series of ${encodedItemName} games.`;
    }
  }

  if (subheading) {
    return desc + subheading;
  }

  return desc.trim();
};

export const updateCommentList = (
  comments: ICommonComment[],
  event: CommentEvent,
  id: number,
): ICommonComment[] => {
  let newComments: ICommonComment[];

  if (event === "comment:delete" || event === "comment:hide") {
    newComments = comments.filter(function (c) {
      return c.id !== id.toString();
    });
  } else if (event === "comment:approve") {
    newComments = comments.map((c) =>
      c.id === id.toString()
        ? {
            ...c,
            publishStatus: stringToPublishStatusType(event),
            replies: updateReplies(c.replies),
          }
        : c,
    );
  }

  return newComments;
};

export const isCurrentUserOrHasRole = (
  user: AuthUser,
  allowRole: string,
  userId: string,
) => {
  if (!user) return false;

  if (user?.roles.indexOf(allowRole) > -1) return true;

  if (user?.userId === userId) return true;
};

export const isEmptyObject = (obj: object) => {
  return Object.keys(obj).length === 0;
};
