import React from "react";
import Button from "~/components/Button";
import { DataCollectorChildProps } from "~/components/DataCollector/types";
import Heading from "~/components/Heading";
import Spacer from "~/components/Spacer";
import { DataCollectorPhrases } from "~/helpers/phrases/data-collector";

interface PickPathProps extends DataCollectorChildProps {
  mainItemName: string;
}

const PickPath = (props: PickPathProps) => {
  const { item, opinionData, mainItemName } = props;
  const appName = item.name;

  let pathHeader = `Why don't you like ${appName || "this app"}?`;

  if (opinionData && opinionData.id) {
    pathHeader = `Why don't you like ${
      appName || "this app"
    } as an alternative to ${mainItemName}?`;
  }

  return (
    <>
      <div data-testid="dc-pickpath">
        <Heading element="h2">{pathHeader}</Heading>
        <Spacer space={1} />
        <p>{DataCollectorPhrases.en.pickPath.body}</p>
        <Spacer space={2} />
        <Button
          subText={DataCollectorPhrases.en.pickPath.notSimilarButtonSubText}
          look="primary"
          fill={true}
          onClick={() => props.done()}
        >
          {DataCollectorPhrases.en.pickPath.notSimilarButton}
        </Button>
        <Spacer space={2} />
        <Button
          fill={true}
          subText={DataCollectorPhrases.en.pickPath.otherButtonSubText}
          onClick={() =>
            props.done({ widgets: ["Recaptcha", "Report", "End"] })
          }
        >
          {DataCollectorPhrases.en.pickPath.otherButton}
        </Button>
      </div>
    </>
  );
};

export default PickPath;
