import { UrlEntity, pageTypeToUrlEntity } from "~/helpers/urls";
import { DefaultPageProps, PageProps } from "~/typings/types";

export const pushDataLayer = (dataLayer: any, event: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer?.push({
    event: event,
    ...dataLayer,
  });
};

export const getTagManagerDataLayer = (pageProps: DefaultPageProps) => {
  //const serverABTest = getABTestProperty(pageProps);
  const isAdsenseBlocked = getIsAdsenseBlockedProperty(pageProps);

  const content_group = pageTypeToUrlEntity(pageProps?.meta?.pageType);

  const pageGroups = getPageGroups(content_group);

  const appClass = getAdditionalMeta(pageProps, "AppClass");

  const pageTitle = document?.title ? document?.title : "Title is missing";
  const pageUrl = window?.location?.href;

  const dataLayer = {
    ...pageGroups,
    a2_adsense_blocked: isAdsenseBlocked,
    a2_appClass: appClass,
    page_title: pageTitle,
    page_location: pageUrl,
  };

  return dataLayer;
};

export const getPageGroups = (urlEntity?: UrlEntity) => {
  const pageGroups = {
    pt_main: urlEntity as string,
    pt_sub: "",
    content_group: urlEntity,
  };

  //if (!pageGroup) return pageGroups;

  if (urlEntity === "category") {
    pageGroups.pt_main = "Browse";
    pageGroups.pt_sub = "Category";
  }

  if (urlEntity.startsWith("app-")) pageGroups.pt_main = "App";

  if (pageGroups.pt_main !== "Unknown") {
    if (urlEntity.endsWith("-alternatives")) pageGroups.pt_sub = "Alternative";
    if (urlEntity.endsWith("-about")) pageGroups.pt_sub = "About";
    if (urlEntity.endsWith("-news")) pageGroups.pt_sub = "News";
  }

  return pageGroups;
};

const getIsAdsenseBlockedProperty = (pageProps: any) => {
  let isAdsenseBlocked = false;

  if (pageProps.showAds != undefined && !pageProps.showAds) {
    isAdsenseBlocked = true;
  }

  if (pageProps.containsAdSenseBlockedItems) {
    isAdsenseBlocked = true;
  }

  if (pageProps.mainItem && pageProps.mainItem.adSenseBlocked) {
    isAdsenseBlocked = true;
  }

  return isAdsenseBlocked;
};

const getAdditionalMeta = (pageProps: PageProps, name: string) => {
  let returnNumber = -1;

  if (
    pageProps.meta &&
    pageProps.meta.additionalMeta &&
    pageProps.meta.additionalMeta.length > 0
  ) {
    const result = pageProps.meta.additionalMeta.filter((p) => {
      return p && p[0] && p[0].indexOf(name) > -1;
    });

    if (result && result[0] && result[0].length > 0) {
      returnNumber = parseInt(result[0][1]);

      if (isNaN(returnNumber)) {
        return -1;
      }
    }
  }

  return returnNumber;
};
