import React, { useState, useContext } from "react";
import Heading from "~/components/Heading";
import Spacer from "~/components/Spacer";
import Textarea from "react-textarea-autosize";
import Button from "~/components/Button";
import { textareaStyles } from "~/components/DataCollector/DataCollectorStyles";
import { DataCollectorChildProps } from "~/components/DataCollector/types";
import { RootContextValues } from "~/typings/types";
import { RootContext } from "~/components/Contexts/RootContext";
import { DataCollectorPhrases } from "~/helpers/phrases/data-collector";
import { callAPIClientSide } from "~/helpers/api";

type Props = DataCollectorChildProps;

const Report = (props: Props) => {
  const [reportComment, setReportComment] = useState("");
  const [reportType, setReportType] = useState("");
  //const [requestInProcess, setRequestInProcess] = useState(false);

  const rootContext = useContext<RootContextValues>(RootContext);

  const sendData = () => {
    //setRequestInProcess(true);

    const body: any = {
      reportComment: reportComment,
      reportType: reportType,
    };

    const postUrl = `/items/${props.item.id}/report/`;

    callAPIClientSide(
      postUrl,
      null,
      "POST",
      rootContext.userId,
      body,
      rootContext.recaptchaToken,
    )
      .then(() => {
        props.done();
        //setRequestInProcess(false);
      })
      .catch((err) => {
        rootContext.setError("Error saving report " + "(" + err.message + ")");
        props.done();
      });
  };

  return (
    <div data-testid="dc-report">
      <Heading element="h2">{DataCollectorPhrases.en.report.header}</Heading>
      <p>{DataCollectorPhrases.en.report.body}</p>
      <Spacer space={4} />
      <select
        data-testid="report-type"
        onChange={(e) => setReportType(e.target.value)}
        style={textareaStyles}
      >
        <option value="">
          {DataCollectorPhrases.en.report.selectPlaceholder}
        </option>
        <option value="discontinued">Discontinued</option>
        <option value="spam">Spam</option>
        <option value="dangerous">Dangerous</option>
        <option value="bundleware">Bundleware</option>
        <option disabled>──────────</option>
        <option value="duplicate">Duplicate</option>
        <option value="incorrectUrl">Incorrect Url / Site unavailable</option>
        <option value="incorrectPrice">Incorrect pricing / licencing</option>
        <option value="incorrectPlatform">Incorrect platforms</option>
        <option value="incorrectDescription">Incorrect description</option>
        <option disabled>──────────</option>
        <option value="other">Other</option>
      </select>

      <Spacer space={2}></Spacer>
      <Textarea
        value={reportComment}
        onChange={(e) => setReportComment(e.target.value)}
        minRows={4}
        maxRows={8}
        style={textareaStyles}
        rows={3}
        placeholder={DataCollectorPhrases.en.report.textBoxPlaceholder}
      />
      <Spacer space={4} />

      <Button
        look="primary"
        fill={true}
        disabled={reportComment.length <= 0 || reportType === ""}
        onClick={() => sendData()}
      >
        {DataCollectorPhrases.en.report.button}
      </Button>
      <em className="meta">
        {reportComment &&
          reportType === "" &&
          "You need to pick a report type before you can submit."}
      </em>
    </div>
  );
};
export default Report;
