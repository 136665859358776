import React from "react";
import { FaStar } from "react-icons/fa";
import ContentWithAlignedIconWrapper from "~/c/Common/ContentWithAlignedIconWrapper";
import { CommonPhrases } from "~/helpers/phrases/common";

interface UserProfileRankProps {
  userRank: number;
  userTitle?: string;
  showIcon?: boolean;
  showLevel?: boolean;
}

const UserProfileRank = (props: UserProfileRankProps) => {
  const { userRank, showIcon, userTitle, showLevel = true } = props;

  // if(!userTitle) {
  //   switch(userRank) {
  //     case 8:
  //       userTitle = "Grandmaster";
  //       break;
  //     case 7:
  //       userTitle = "Master";
  //       break;
  //     case 6:
  //       userTitle = "Expert";
  //       break;
  //     case 5:
  //       userTitle = "Pro";
  //       break;
  //     case :
  //       userTitle = "Adept";
  //       break;
  //     case 6:
  //       userTitle = "Newbie";
  //       break;
  //   })
  // }

  // let userLevelIcon = (
  //   <FaUser
  //     title={CommonPhrases.en.userRank + " " + userRank}
  //     style={{ marginRight: "4px" }}
  //   ></FaUser>
  // );

  // if (userRank > 6) {
  //   userLevelIcon = (
  //     <>
  //       <FaCrown
  //         title={CommonPhrases.en.userRank + " " + userRank}
  //         style={{ marginRight: "4px" }}
  //         color="#e39d06"
  //       ></FaCrown>
  //     </>
  //   );
  // } else if (userRank > 2) {
  //   userLevelIcon = (
  //     <FaStar
  //       title={CommonPhrases.en.userRank + " " + userRank}
  //       style={{ marginRight: "4px" }}
  //       color="#e39d06"
  //     ></FaStar>
  //   );
  // }

  const isAdmin = userTitle == "Administrator" || userTitle == "Webmaster";

  const userLevelIcon = (
    <FaStar
      title={CommonPhrases.en.userRank + " " + userRank}
      style={{ marginRight: "4px" }}
      color="#e39d06"
    ></FaStar>
  );

  return (
    <ContentWithAlignedIconWrapper>
      {showIcon && userLevelIcon}
      {showLevel && !isAdmin && (
        <>
          {CommonPhrases.en.userRank} {userRank}
        </>
      )}
      {isAdmin ? (
        <span style={{ alignSelf: "flex-end" }}>&nbsp;{userTitle}</span>
      ) : (
        ""
      )}
    </ContentWithAlignedIconWrapper>
  );
};
export default UserProfileRank;
