import {
  ItemForList,
  FeatureWithUserInfo,
  ICommonComment,
  ItemImage,
  CommonImage,
  AboutItem,
} from "~/typings/types";
import { DataCollectorReturnData } from "~/components/DataCollector/types";
import { AppPagePhrases } from "~/helpers/phrases/app-pages";
//import { truncate } from "./stringHelpers";

// export const getShortDescOrTagLine = (desc: string, tagline: string) => {
//   if (tagline && tagline.length > 150) {
//     return tagline;
//   }

//   let returnDesc = desc;

//   if (desc && desc.length > 150) {
//     returnDesc = truncate(desc, 280, true, true);
//   }

//   return returnDesc;
// };

export const optimizeAlternatives = (item: ItemForList) => {
  /**
   * Reduce data sent from SSR -> client by removing unended keys from API response
   */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { description, tagLine, pricing, ...rest } = item;

  rest.highlightTags = item.highlightTags.map((tag) => {
    const { titleSuffix, titleOption, featureCategory, ...rest } = tag;

    return rest;
  });

  rest.tags = item.tags.map((tag) => {
    const { titleSuffix, titleOption, featureCategory, ...rest } = tag;

    /* eslint-enable @typescript-eslint/no-unused-vars */

    return rest;
  });

  rest.images = optimizeItemImages(rest.images, "70+");

  return rest;
};

export const optimizeNativeBanner = (item: AboutItem) => {
  /**
   * Reduce data sent from SSR -> client by removing unended keys from API response
   */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    description,
    tagLine,
    pricing,
    pricingInfoHtml,
    categories,
    features,
    platformsWithNote,
    addedByUser,
    videos,
    itemMetaData,
    supportedLanguages,
    tags,
    topAlternatives,
    platforms,
    appTypes,
    topAppsWithCurrentAsPlatform,
    licenseData,
    ...rest
  } = item;

  // rest.highlightTags = item.highlightTags.map((tag) => {
  //   const { type, titleSuffix, titleOption, featureCategory, ...rest } = tag;

  //   return rest;
  // });

  // rest.tags = item.tags.map((tag) => {
  //   const { titleSuffix, titleOption, featureCategory, ...rest } = tag;

  //   /* eslint-enable @typescript-eslint/no-unused-vars */

  //   return rest;
  // });

  return rest;
};

export const optimizeComments = (
  comments: ICommonComment[],
  baseProfileImageSize: number,
) => {
  /**
   * Reduce data sent from SSR -> client by removing unended keys from API response
   */

  const optimizedComments = comments.map((comment) => {
    /* eslint-disable @typescript-eslint/no-unused-vars */

    comment.user.image = optimizeUserImage(
      comment.user.image,
      baseProfileImageSize,
    );

    /* eslint-enable @typescript-eslint/no-unused-vars */

    return comment;
  });

  return optimizedComments;
};

export const optimizeForAppTopList = (
  items: ItemForList[],
  baseIconSize: number,
  keepScreenshots: boolean = false,
  keepProperties?: string[],
) => {
  return items.map((originalItem) => {
    const item = { ...originalItem };

    let clearProperties = [
      "description",
      "tagLine",
      "shortDescriptionOrTagLine",
      "pricing",
      "platforms",
      //"tags",
      "itemAlerts",
      //"metaTags",
      "id",
      "adSenseBlocked",
    ];

    if (keepProperties) {
      keepProperties.forEach((property) => {
        clearProperties = clearProperties.filter((p) => p !== property);
      });
    }

    clearProperties.forEach((property) => {
      if (property in item) {
        delete item[property];
      }
    });

    if (!keepScreenshots) {
      item["images"] = item.images.filter(
        (image) => image.type !== "Screenshot",
      );
    }

    item["images"] = optimizeItemImages(
      item["images"],
      baseIconSize.toString(),
    );

    return item;
  });
};

export const optimizeItemImages = (
  images: ItemImage[],
  baseIconSize: string,
  mode: "icons" | "screenshots" | "both" = "both",
) => {
  return images.map((image) => {
    if (!image.signedImages) {
      return image;
    }

    if (image.type.toLowerCase() === "icon" && mode !== "screenshots") {
      image.signedImages = image?.signedImages?.filter((image) => {
        const size = image.size;

        if (baseIconSize == "140") {
          return size === "140x140" || size === "280x280";
        } else if (baseIconSize == "70") {
          return size === "70x70" || size === "140x140";
        } else if (baseIconSize == "70+") {
          return size === "70x70" || size === "140x140" || size === "280x280";
        } else if (baseIconSize == "40") {
          return size === "40x40" || size === "70x70";
        }
      });
    }
    // else if (image.type.toLowerCase() === "screenshot" && mode !== "icons") {
    //   image.signedImages = image?.signedImages?.filter((signedImage) => {
    //     const size = signedImage.size.toLowerCase();

    //     return (
    //       size === "landscape-new" ||
    //       size === "hi-res-landscape-new" ||
    //       size === "portrait-new" ||
    //       size === "hi-res-portrait-new" ||
    //       size === "2400x2400"
    //     );
    //   });
    // }

    return image;
  });
};

export const optimizeUserImage = (image: CommonImage, baseSize: number) => {
  if (image == null) {
    return null;
  }

  if (!image.signedImages) {
    return image;
  }

  image.signedImages = image?.signedImages?.filter((image) => {
    const size = image.size;

    if (baseSize == 20) {
      return size === "20x20" || size === "40x40";
    } else if (baseSize == 40) {
      return size === "40x40" || size === "80x80";
    } else if (baseSize == 80) {
      return size === "80x80" || size === "160x160";
    } else if (baseSize == 160) {
      return size === "160x160" || size === "320x320";
    }
  });

  return image;
};

export const GetAppTypeAndSuffix = (appType: string, suffix: string) => {
  let appTypeAndSuffix = appType;

  if (suffix && suffix !== "-") {
    appTypeAndSuffix += " " + suffix;
  }

  return appTypeAndSuffix;
};

export const prepareAppListData = (
  item: ItemForList,
  dataCollectorReturnedData?: DataCollectorReturnData,
) => {
  const features: FeatureWithUserInfo[] =
    item.tags &&
    item.tags
      .filter((tag) => tag.type === "Feature")
      .map((tag) => {
        const { ...rest } = tag;

        const returnFeature: FeatureWithUserInfo = {
          likes: 0,
          name: rest.name,
          ...rest,
        };

        return returnFeature;
      });

  // Find the item's icon and screenshots
  const icon =
    item.images && item.images.find((image) => image.type === "Icon");
  const screenshots =
    item.images && item.images.filter((image) => image.type === "Screenshot");

  if (
    dataCollectorReturnedData &&
    dataCollectorReturnedData.type === "AlternativeComment" &&
    dataCollectorReturnedData.id === item.id
  ) {
    if (dataCollectorReturnedData) {
      // If the item was already added, do not add it.
      if (
        !item.comments.items.find((item) => {
          return (
            item.id === (dataCollectorReturnedData.data as ICommonComment).id
          );
        })
      ) {
        item.comments.items.push(
          dataCollectorReturnedData.data as ICommonComment,
        );
      }
    }
  }

  const comments = item.comments;

  return {
    features,
    icon,
    screenshots,
    comments,
  };
};

interface OpinionStats {
  yesPercentage: number;
}

export const getOpinionStatsText = (
  opinionStats: OpinionStats,
  mainItemName: string,
  alternativeName: string,
): string => {
  let opinionStatsText = "";

  if (opinionStats.yesPercentage > 90) {
    opinionStatsText =
      AppPagePhrases.en.alternativeList.opinionStatsAlmostEveryone;
  } else if (opinionStats.yesPercentage > 65) {
    opinionStatsText = AppPagePhrases.en.alternativeList.opinionStatsMostUsers;
  } else if (opinionStats.yesPercentage > 40) {
    opinionStatsText = AppPagePhrases.en.alternativeList.opinionStatsSomeUsers;
  } else {
    opinionStatsText = AppPagePhrases.en.alternativeList.opinionStatsDisputed;
  }

  return opinionStatsText
    .replace(/{mainItemName}/g, mainItemName)
    .replace(/{appName}/g, alternativeName);
};
