import React from "react";
import { forwardRef } from "react";
import { ListOfUsers } from "~/c/ListOfUsers";
import { GetAppTypeAndSuffix } from "~/helpers/appListHelper";
import { FeatureWithUserInfo, UnsavedFeature } from "~/typings/types";
import styles from "./FeatureListItem.module.scss";

interface FeatureListItemProps {
  feature: FeatureWithUserInfo | UnsavedFeature;
  isSelectedByUser: boolean;
  itemName: string;
  onChange(): any;
}

// eslint-disable-next-line react/display-name
export const FeatureListItem = forwardRef<HTMLLIElement, FeatureListItemProps>(
  (props: FeatureListItemProps, ref) => {
    const { feature, isSelectedByUser, onChange, itemName } = props;

    let featureVote = feature.name + " is a feature on " + itemName;

    const appTypeWithSuffix = GetAppTypeAndSuffix(
      feature.appType,
      feature.titleSuffix,
    );

    if (feature.appType) {
      featureVote = itemName + " is a " + appTypeWithSuffix;
    }

    return (
      <React.Fragment key={feature.name}>
        <li ref={ref} key={feature.name}>
          <label
            className={`${styles.featureItem} ${isSelectedByUser ? styles.isSelected : ""}`}
            title={featureVote}
          >
            <input
              id={`checkFeature-${feature.groupName}`}
              data-testid={`checkFeature-${feature.groupName}`}
              type="checkbox"
              checked={isSelectedByUser}
              onChange={onChange}
              className={styles.chevronCheckbox}
            />{" "}
            <label
              className={styles.featureLabel}
              htmlFor={`checkFeature-${feature.groupName}`}
            >
              {feature.appType
                ? GetAppTypeAndSuffix(feature.appType, feature.titleSuffix)
                : feature.name}{" "}
            </label>
            <div className={styles.voteMeta}>
              {feature.likedByUsers && feature.likedByUsers.length > 0 && (
                <ListOfUsers
                  totalOpinions={feature.likes}
                  users={feature.likedByUsers}
                  titleText="think this feature is important"
                  testId={"profile-pictures-" + feature.groupName}
                  type="feature"
                />
              )}
            </div>
          </label>
        </li>
      </React.Fragment>
    );
  },
);
