import React, { Component } from "react";
import {
  DataCollectorChildProps,
  DataCollectorReturnData,
} from "~/components/DataCollector/types";
import Heading from "~/components/Heading";
import Button from "~/components/Button";
import Spacer from "~/components/Spacer";
import Textarea from "react-textarea-autosize";
import { textareaStyles } from "~/components/DataCollector/DataCollectorStyles";
import { VoteType, ICommonComment } from "~/typings/types";
//import * as gtag from "~/helpers/analytics/gtag";
import { GetUTCDate } from "~/helpers/date";
import StarRating from "~/components/Common/StarRating";
import { DataCollectorPhrases } from "~/helpers/phrases/data-collector";
import { RootContext } from "~/components/Contexts/RootContext";
import { CommonPhrases } from "~/helpers/phrases/common";
import { callAPIClientSide } from "~/helpers/api";

interface Props extends DataCollectorChildProps {
  opinionData?: { id: number; opinion: VoteType };
  mainItemName?: string;
  articleId?: number;
}

interface State {
  requestInProcess: boolean;
  comment: string;
  rating: number;
}

export default class Comment extends Component<Props, State> {
  state = {
    requestInProcess: false,
    comment: "",
    rating: 0,
  };

  abortController = new AbortController();

  // Load context
  context: React.ContextType<typeof RootContext>;
  static contextType = RootContext;

  updateComment = (comment: string) => {
    this.setState({ comment });
  };

  updateRating = (rating: number) => {
    this.setState({ rating: rating });
  };

  sendData = (): Promise<any> => {
    this.setState({ requestInProcess: true });

    const body: any = {
      comment: this.state.comment,
      rating: this.state.rating,
    };

    let postUrl = `/threads/`;

    if (this.props.opinionData && this.props.opinionData.id) {
      postUrl = `/items/alternative/comment/`;
      body.alternativeOpinionId = this.props.opinionData.id;
    } else if (this.props.articleId && this.props.articleId > 0) {
      body.articleId = this.props.articleId;
    } else {
      body.itemId = this.props.item.id;
    }

    return callAPIClientSide(
      postUrl,
      null,
      "POST",
      this.context.userId,
      body,
      this.context.recaptchaToken,
    )
      .then((res) => {
        // gtag.default.simple_event({
        //   action: "PostComment",
        //   category: "DataCollector",
        //   label: postUrl,
        // });

        this.setState({
          requestInProcess: false,
        });
        return res;
      })
      .catch((err) => {
        console.debug("Post comment error: " + err);
        this.context.setError(
          "Error saving comment " + "(" + err.message + ")",
        );
      });
  };

  componentWillUnmount() {
    this.abortController.abort();
  }

  render() {
    const { item, opinionData, mainItemName, done, articleId } = this.props;
    const { comment, rating } = this.state;
    const appName = item && item.name;

    let commentHeader = `Rate and review`;
    let placeHolderText = `I like ${appName || "this app"} because it's…`;

    const isAlternativeComment = opinionData && opinionData.id;
    const isArticleComment = articleId && articleId > 0;

    if (opinionData && opinionData.id) {
      commentHeader = `Why do${
        opinionData.opinion === "disagree" ? "n't" : ""
      } you like ${
        appName || "this app"
      } as an alternative to ${mainItemName}?`;

      placeHolderText = `I ${
        opinionData.opinion === "disagree" ? "don't " : ""
      }like ${
        appName || "this app"
      } as an alternative to ${mainItemName} because…?`;
    }

    if (isArticleComment) {
      commentHeader = `Your opinion is noted!`;
      placeHolderText = "Write your comment here ...";
    }

    let buttonText = CommonPhrases.en.save;

    if (comment.length > 0 && rating == 0) {
      buttonText = DataCollectorPhrases.en.comment.postComment;
    }

    if (comment.length > 0 && rating > 0) {
      buttonText = DataCollectorPhrases.en.comment.postReview;
    }

    return (
      <div data-testid="dc-comment">
        <Heading element="h2">{commentHeader}</Heading>
        <p>
          {isArticleComment
            ? DataCollectorPhrases.en.comment.body_news
            : DataCollectorPhrases.en.comment.body}
        </p>

        {!(isAlternativeComment || isArticleComment) && (
          <div className="star-container">
            <StarRating
              onRated={(rated) => {
                this.updateRating(rated);
              }}
              readOnly={false}
              initialRating={0}
            ></StarRating>
          </div>
        )}
        <Spacer space={4} />
        <Textarea
          value={comment}
          onChange={(e) => this.updateComment(e.target.value)}
          minRows={4}
          maxRows={8}
          style={{ ...textareaStyles }}
          rows={3}
          placeholder={placeHolderText}
        />

        <Spacer space={2} />
        <Button
          look="primary"
          fill={true}
          disabled={comment.length <= 0 && rating <= 0}
          onClick={() =>
            this.sendData().then((c) => {
              const returnComment: ICommonComment = {
                addedDate: GetUTCDate(new Date()),
                agree: 0,
                disagree: 0,
                commentMood:
                  this.props.opinionData &&
                  this.props.opinionData.opinion == "agree"
                    ? "Positive"
                    : this.props.opinionData &&
                        this.props.opinionData.opinion == "disagree"
                      ? "Negative"
                      : "Neutral",
                haveOpinionOnComment: "none",
                publishStatus: "Approved",
                text: this.state.comment,
                id: c && c.toString(),
                user: {
                  nick: "you",
                  urlNick: "",
                  userId: "",
                  userRank: 0,
                },
              };

              const itemId = this.props.item && this.props.item.id;
              let returnData: DataCollectorReturnData = null;

              if (itemId) {
                returnData = {
                  type: isAlternativeComment
                    ? "AlternativeComment"
                    : "RegularComment",
                  data: returnComment,
                  id: itemId,
                };
              } else if (isArticleComment) {
                returnData = {
                  type: "ArticleComment",
                  data: returnComment,
                  id: articleId.toString(),
                };
              }

              done(returnData);
            })
          }
        >
          {buttonText}
        </Button>
        <style jsx>{`
          .star-container {
            text-align: center;
            font-size: 2.5em;
          }
        `}</style>
      </div>
    );
  }
}
