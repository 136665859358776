export const AdminPhrases = {
  en: {
    appClass: {
      veryLowDesc:
        "We basically do not care about this app. It has little to no recognition on the site or elsewhere.",
      lowDesc:
        "A OK app but not famous / popular. It has little recognition on the site or elsewhere.",
      mediumDesc:
        "A good app that is semi-famous and might have some potential, people use this app and may have some recognition on the site or elsewhere.",
      highDesc:
        "A very famous app that your family members might have heard of or are very popular in its niche. May have a lot of visitors or good potential to attract a lot of visitors or earn money.",
      veryHighDesc:
        "Extremely important app for us, either because massive potential commercially, has a ton of page views, and / or making good money.",
    },
  },
};
