import React from "react";
import { FaPlus } from "react-icons/fa";

interface SimpleShowMoreToggleProps {
  onShowMore: (event) => void;
  onReset?: (event) => void;
  text: string;
  enablePaging: boolean;
  totalItems?: number;
  showingItems?: number;
  initialItems?: number;
  addEmptyDivForCLS?: boolean;
  pagingSuffix?: string;
  timeline?: boolean;
  suffixIcon?: React.ReactNode;
  className?: string;
}

const SimpleShowMoreToggle = (props: SimpleShowMoreToggleProps) => {
  const {
    onShowMore,
    onReset,
    text,
    enablePaging,
    totalItems,
    showingItems,
    initialItems,
    addEmptyDivForCLS = false,
    pagingSuffix,
    timeline = false,
    className,
  } = props;

  let {
    suffixIcon = (
      <span data-testid="suffix-icon" className="text-[120%] leading-5">
        ▾
      </span>
    ),
  } = props;

  const isMoreItemsToShow = showingItems < totalItems;
  const showingMoreThanInitial = showingItems > initialItems;
  const showMainShowMore = totalItems === 0 || isMoreItemsToShow;
  const showMoreMeta = enablePaging && totalItems > initialItems;

  let prefixIcon = <FaPlus size={10} />;

  const timelineClasses = timeline
    ? "flex items-center -mt-2  text-[0.95em] cursor-pointer"
    : "cursor-pointer";

  if (timeline) {
    suffixIcon = <></>;
  } else {
    prefixIcon = <></>;
  }

  return (
    <>
      {showMainShowMore || showMoreMeta ? (
        <div
          className={`flex mt-1 text-sm relative text-meta  ${
            !isMoreItemsToShow ? "mb-5" : ""
          } ${className || "justify-center"}`}
        >
          {showMainShowMore && (
            <span className={`${timelineClasses}`} onClick={onShowMore}>
              {timeline && prefixIcon && (
                <span
                  data-testid="timeline-icon"
                  className="z-[8] rounded-[14px] bg-main-bg border border-gray-300 p-1  w-[20px] h-[20px] ml-[11px] mr-3"
                >
                  {prefixIcon}
                </span>
              )}
              {text} {suffixIcon}
            </span>
          )}
          {showMoreMeta && (
            <div className="absolute right-0 text-sm">
              {showingMoreThanInitial && <span onClick={onReset}>Hide | </span>}{" "}
              {showingItems} of {totalItems} {pagingSuffix && pagingSuffix}
            </div>
          )}
        </div>
      ) : (
        addEmptyDivForCLS && (
          <div data-testid="empty-div" className="h-5">
            &nbsp;
          </div>
        )
      )}
    </>
  );
};

export default SimpleShowMoreToggle;
