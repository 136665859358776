import * as React from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { DataCollectorChildProps } from "~/components/DataCollector/types";
import Heading from "~/components/Heading";
import Spacer from "~/components/Spacer";

import { useEffect } from "react";

type RecaptchaProps = DataCollectorChildProps;

const Recaptcha = (props: RecaptchaProps) => {
  let introText = "";

  if (props.item) {
    introText = `Even though you don't create an account we still value your opinion, please solve this captcha and we'll ask a few questions about ${props.item.name}. You can always create an account to get rid of this captcha.`;
  } else {
    introText = `Even though you don't create an account we still value your opinion, please solve this captcha and we'll save your opinion. You can always create an account to get rid of this captcha.`;
  }

  let captchaSitekey = "0d1d0536-380b-4e37-ba3b-b7fcfa4122d3";

  const DEPLOY_ENV = process.env.DEPLOY_ENV;

  if (
    process.env.NODE_ENV === "development" ||
    DEPLOY_ENV === "development" ||
    DEPLOY_ENV === "test" ||
    process.env.NODE_ENV === "test"
  ) {
    captchaSitekey = "10000000-ffff-ffff-ffff-000000000001";
  }

  // Need to be in useEffect for stuff to happen in correct order.
  useEffect(() => {
    if (
      (window as any).Cypress &&
      captchaSitekey === "10000000-ffff-ffff-ffff-000000000001"
    ) {
      setTimeout(() => {
        props.done({ token: "token" });
      }, 500);
    }
  }, []);

  return (
    <div data-testid="dc-captcha">
      <Heading element="h2">No worries!</Heading>
      <p data-testid="intro-text">{introText}</p>
      <Spacer space={4} />
      <form>
        <HCaptcha
          sitekey={captchaSitekey}
          onVerify={(token) => props.done({ token: token })}
        />
      </form>
    </div>
  );
};

export default Recaptcha;
