import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { UpDownVoterPhrases } from "~/helpers/phrases/upDownVoter";
import { UpAndDownVote, VoteType } from "~/typings/types";
import classNames from "classnames";

type IconType = "Chevron" | "Thumbs";
type FlexDirection = "row" | "column";

type UpDownVoterProps = {
  upVote: boolean;
  downVote: boolean;
  upElement: ReactNode;
  downElement: ReactNode;
  type: "Comment" | "Alternative" | "News" | "Feature";
  voteText?: string | ReactNode;
  toggleVote(type: VoteType): any;
  iconType?: IconType;
  direction?: FlexDirection;
  style?: CSSProperties;
  haveComment?: boolean;
  isLoggedIn?: boolean;
  commentVoteNumber?: UpAndDownVote;
  upVoteText?: string;
  downVoteText?: string;
};

const UpDownVoter = (props: UpDownVoterProps) => {
  const {
    toggleVote,
    type,
    style,
    commentVoteNumber,
    haveComment,
    isLoggedIn,
    upElement,
    downElement,
    direction = "column",
    voteText = "",
    upVoteText = "",
    downVoteText = "",
  } = props;

  const [voteSum, setVoteSum] = useState(
    commentVoteNumber?.upVote - commentVoteNumber?.downVote,
  );

  const [downVote, setDownVote] = useState(false);
  const [upVote, setUpVote] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showVoteButtons, setShowVoteButtons] = useState(true);
  const [voteTextState, setVoteTextState] = useState(voteText);

  useEffect(() => {
    setUpVote(props.upVote);
    setDownVote(props.downVote);
  }, [props]);

  useEffect(() => {
    if (
      (type === "Alternative" || type === "News" || type === "Feature") &&
      (upVote || downVote)
    ) {
      let vText = voteTextState;

      if (upVote) {
        if (type === "Alternative") {
          vText = UpDownVoterPhrases.en.haveUpVotedAlternative;
        } else if (type === "Feature") {
          vText = UpDownVoterPhrases.en.haveUpVotedFeature;
        } else {
          vText = UpDownVoterPhrases.en.haveUpVotedNews;
        }
        setShowVoteButtons(false);
        setVoteTextState(vText);

        setShowRemove(true);
      }
      if (downVote) {
        if (type === "Alternative") {
          vText = UpDownVoterPhrases.en.haveDownVotedAlternative;
        } else if (type === "Feature") {
          vText = UpDownVoterPhrases.en.haveDownVotedFeature;
        } else {
          vText = UpDownVoterPhrases.en.haveDownVotedNews;
        }

        setShowVoteButtons(false);
        setVoteTextState(vText);

        setShowRemove(true);
      }
    }

    if (type === "Feature") {
      setShowRemove(false);
    }

    if (!isLoggedIn || (type === "Alternative" && haveComment)) {
      setShowRemove(false);
    }
  }, [upVote, downVote]);

  useEffect(() => {
    if (showVoteButtons) {
      setShowRemove(false);
    }
  }, [showVoteButtons]);

  const handleVote = (type: "up" | "down") => {
    if (type == "up") {
      if (!upVote) {
        let add = 1;
        if (downVote) add = 2;
        setVoteSum(voteSum + add);
        setUpVote(true);
        setDownVote(false);
        toggleVote("agree");
      }
    } else {
      if (!downVote) {
        let rem = 1;
        if (upVote) rem = 2;

        setVoteSum(voteSum - rem);
        setUpVote(false);
        setDownVote(true);

        toggleVote("disagree");
      }
    }
  };

  return (
    <>
      <div
        style={style}
        className={classNames(
          "my-auto flex items-center text-meta-light font-normal",
          {
            "text-[110%]": type === "News",
            "text-main-fg": type === "Alternative",
            "flex-col": direction === "column",
            "flex-row": direction !== "column",
          },
        )}
      >
        {voteTextState && (
          <span
            className="hidden md:block text-meta text-sm"
            style={{ marginRight: type === "Feature" ? "2px" : "12px" }}
          >
            {voteTextState}
          </span>
        )}
        {showVoteButtons && (
          <div
            data-role="v-up"
            title={upVoteText}
            className={classNames(
              "cursor-pointer px-0.5 text-meta-light transition-colors",
              {
                "text-positive-greener": upVote,
                "hover:text-positive-greener": !upVote,
              },
            )}
            onClick={() => handleVote("up")}
          >
            {upElement}
          </div>
        )}
        {commentVoteNumber ? (
          <span className="px-1 md:px-2 font-medium text-meta text-xs relative">
            {voteSum}
          </span>
        ) : (
          <span>&nbsp;</span>
        )}
        {showVoteButtons && (
          <div
            data-role="v-down"
            title={downVoteText}
            className={classNames(
              "cursor-pointer px-0.5 text-meta-light transition-colors",
              {
                "text-danger": downVote,
                "hover:text-danger": !downVote,
              },
            )}
            onClick={() => handleVote("down")}
          >
            {downElement}
          </div>
        )}
        {showRemove && (
          <div
            title={UpDownVoterPhrases.en.removeVote}
            className="cursor-pointer px-0.5 text-meta-light"
          >
            <span
              data-testid="remove-vote"
              onClick={() => toggleVote("remove")}
              className="relative block rounded-full bg-meta p-0.5 text-white hover:bg-danger leading-[10px]"
            >
              <FaTimes
                size={10}
                title={UpDownVoterPhrases.en.removeVoteTitle}
              />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default UpDownVoter;
