"use client";

import React, { useState, useEffect, CSSProperties } from "react";

type LoadingSkeletonProps = {
  repeat: number;
  style?: CSSProperties;
  delay?: number;
};

const LoadingSkeleton = (props: LoadingSkeletonProps) => {
  const { repeat, style, delay = 300 } = props;

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true);
    }, delay);
    return () => clearTimeout(timer);
  }, []);

  const rows = [];
  for (let i = 0; i < repeat; i++) {
    // note: we add a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    rows.push(<div key={i} style={style} className="loading-skeleton"></div>);
  }

  return (
    <>
      {showLoading && <>{rows}</>}
      <style jsx global>{`
        .loading-skeleton {
          display: block;
          height: 100%;
          width: 100%;
          border-radius: var(--borderRadius);
          margin-top: 12px;
          background: linear-gradient(
            -90deg,
            var(--gray100) 0%,
            var(--gray200) 50%,
            var(--gray100) 100%
          );

          background-size: 400% 400%;
          animation: pulse 1.2s ease-in-out infinite;
        }

        @keyframes pulse {
          0% {
            background-position: 0% 0%;
          }
          100% {
            background-position: -135% 0%;
          }
        }

        .loading-skeleton:before {
          content: ${`"\\00a0"`};
        }
      `}</style>
    </>
  );
};

export default LoadingSkeleton;
