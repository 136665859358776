import React from "react";

type CommonModalProps = {
  children: any;
  title: string;
  buttonElements: any;
  className?: string;
};

const CommonModal = (props: CommonModalProps) => {
  const { title, buttonElements, children, className } = props;
  return (
    <>
      <div className={`actual-content ${className}`}>
        <div className="header">
          <p>{title}</p>
          <div className="header-buttons">{buttonElements}</div>
        </div>
        <div className="body">{children}</div>
      </div>
      <style jsx>{`
        .actual-content {
          width: 100%;
        }
        .header p {
          font-weight: 500;
        }
        .header {
          display: flex;
          align-items: center;
          padding: 2px 25px;
          border-bottom: 1px solid var(--gray100);
          color: #767676;
          font-size: 0.8em;
        }

        .header-buttons {
          margin-left: auto;
          display: flex;
        }

        @media screen and (max-width: 600px) {
          .actual-content {
            display: none;

            &.chat-modal {
              display: block;
            }
          }
          .actual-content.mobile-pitch-clicked {
            display: block;
          }
        }
        @media screen and (width: 421px) {
          .actual-content {
            display: block;
          }
        }
        .body {
          padding: 15px 25px 25px;
        }
      `}</style>
    </>
  );
};

export default CommonModal;
