/* eslint-disable @next/next/no-img-element */
/**
 * This component is a stripped-down version of <Search />. Its job it
 * to autocomplete the tags that are typed into the search box.
 */
import React, { useState, useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import Autocomplete from "downshift";
import { UnsavedFeature } from "~/typings/types";
import Button from "./Button";
import { DataCollectorPhrases } from "~/helpers/phrases/data-collector";
import breakpoints from "~/helpers/breakpoints";

type AutoCompleteProps = {
  inputDisabled?: boolean;
  onSubmit?: (data: UnsavedFeature) => void;
  //refine?: any; // Injected
  //currentRefinement?: string;
};

const AutoCompleteForFeature = (props: AutoCompleteProps) => {
  const [inputValue, setInputValue] = useState("");
  const [searchData, setSearchData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const client = algoliasearch(
    "ZIDPNS2VB0",
    "88489cdf3a8fbfe07a2f607bf1568330",
  );

  const algoliaTagIndex = process.env.NEXT_PUBLIC_ALGOLIA_TAG_INDEX;

  const index = client.initIndex(algoliaTagIndex);

  const getItems = (value: string) => {
    index
      .search(value, {
        hitsPerPage: 10,
        facetFilters: [["type:Feature", "type:Described", "type:Property"]],
      })
      .then(({ hits }) => {
        hits = hits.filter(
          (hit: any) =>
            hit.type === "Feature" ||
            hit.type === "Property" ||
            (hit.type === "Described" && hit.appType),
        );
        if (hits.length > 0) {
          setSearchData(hits);
          setIsOpen(true);
        } else {
          setIsOpen(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //let { hits } = props;
  const { inputDisabled } = props;

  const submitData = (feature: UnsavedFeature): void => {
    const { onSubmit } = props;
    onSubmit(feature);
  };

  const inputIsValid = () => {
    if (inputValue && inputValue.length > 1) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (inputValue === "") {
      setIsOpen(false);
    }
  }, [inputValue]);

  return (
    <>
      <Autocomplete
        inputValue={inputValue}
        itemToString={(i) => (i ? i.LongText : i)}
      >
        {({ getInputProps, getItemProps, getMenuProps, highlightedIndex }) => (
          <div className="feature-search">
            <input
              type="text"
              name="q"
              placeholder={DataCollectorPhrases.en.feature.textBoxPlaceHolder}
              disabled={inputDisabled}
              {...getInputProps({
                onKeyUp: (e: any) => {
                  // Key up and down
                  if (e.which === 38 || e.which == 40) {
                    const hit = searchData[highlightedIndex];

                    // SetState provided by the render prop above
                    setInputValue(hit && hit.longText);
                  }
                  // Enter
                  if (e.which === 13) {
                    // Arbitrary validation: min 2 chars
                    if (inputIsValid()) {
                      submitData({
                        name: inputValue,
                        groupName: inputValue.replace(/\s/g, "-").toLowerCase(),
                      });
                      setInputValue("");
                      setIsOpen(false);
                    }
                  }
                  // Escape
                  if (e.which === 27) {
                    // Arbitrary validation: min 2 chars

                    setInputValue("");
                    setIsOpen(false);
                  }
                },
              })}
              onChange={(evt) => {
                //refine(evt.target.value);
                setInputValue(evt.target.value);
                getItems(evt.target.value);
              }}
            />
            <Button
              aria-label="Add"
              look="primary"
              style={{
                position: "absolute",
                top: 2,
                right: 2,
                bottom: 2,
                padding: "0 10px",
                fontSize: "0.85em",
              }}
              disabled={!inputIsValid()}
              onClick={() => {
                if (inputIsValid()) {
                  submitData({
                    name: inputValue,
                    groupName: inputValue.replace(/\s/g, "-").toLowerCase(),
                  });
                  setInputValue("");
                  setIsOpen(false);
                }
              }}
            >
              {DataCollectorPhrases.en.feature.addButton}
            </Button>
            {isOpen && inputIsValid() && searchData.length > 0 && (
              <div style={styles.hitsWrapper} {...getMenuProps()}>
                <div style={styles.hitsWrapperOverflow}>
                  {searchData.map((item, index) => {
                    const displayText =
                      item.appType && item.type === "Described"
                        ? item.appType
                        : item.longText;

                    return (
                      <div
                        key={item.objectID}
                        {...getItemProps({
                          item,
                          index,
                          onClick: () => {
                            submitData({
                              name: item.longText,
                              groupName: item.groupName,
                              appType: item.appType,
                              type: item.type,
                            });
                            setInputValue("");
                          },
                          style: {
                            background:
                              highlightedIndex === index
                                ? "var(--gray250)"
                                : "transparent",
                          },
                        })}
                      >
                        <span style={{ ...styles.hitWrapper }}>
                          {displayText}{" "}
                          {item.appType && (
                            <span className="meta"> (Application type)</span>
                          )}
                          {item.type === "Property" && (
                            <span className="meta"> (Property)</span>
                          )}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <a href="https://www.algolia.com/">
                  <img
                    src="/static/search-by-algolia.svg"
                    alt="Autocomplete magic by Algolia"
                    style={styles.algoliaAttribution}
                  />
                </a>
              </div>
            )}
          </div>
        )}
      </Autocomplete>
      <style jsx global>
        {`
          .feature-search {
            position: relative;
            z-index: 5;
          }
          .feature-search .ais {
            display: flex;
            flex-grow: 1;
          }
          .feature-search input[type="text"] {
            width: 100%;
            display: block;
            padding: 8px 8px;
            appearance: none;
            border: none;
            border-radius: 1px;
            font-size: 16px; /* Prevents iOS from zooming in on focus */
            box-shadow: 0 0 0 1px var(--gray250);
          }
          .feature-search input:not([disabled]):focus,
          .feature-search input:not([disabled]):active {
            outline: none;
            box-shadow: 0 0 0 1px black;
          }
          .feature-search input[disabled] {
            opacity: 0.5;
          }
          /* Prevents zoom-in on focus on iOS */
          @media screen and (${breakpoints.smUp}) {
            .feature-search input {
              font-size: 16px;
            }
          }
        `}
      </style>
    </>
  );
};

const styles = {
  hitsWrapper: {
    position: "absolute",
    boxShadow: "0 10px 50px rgba(var( --mainFgRgb),.15)",
    borderRadius: 2,
    transform: "translateY(2px)",
    width: "100%",
    fontSize: ".8em",
    zIndex: 1,
    background: "var(--mainBg)",
  },
  hitsWrapperOverflow: {
    maxHeight: 220,
    overflow: "auto",
  },
  hitWrapper: {
    display: "block",
    overflow: "hidden",
    padding: "4px 8px",
    borderBottom: "1px solid var(--gray100)",
    textDecoration: "none",
    cursor: "pointer",
  },
  algoliaAttribution: {
    display: "block",
    margin: "2px auto 5px",
    height: 13,
  },
};

export default AutoCompleteForFeature;
