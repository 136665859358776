import {
  DataCollectorChildProps,
  DataCollectorReturnData,
} from "~/components/DataCollector/types";
import Heading from "~/components/Heading";
import Button from "~/components/Button";
import Spacer from "~/components/Spacer";

import React, { useContext } from "react";
import { VoteType, RootContextValues } from "~/typings/types";
import { RootContext } from "~/components/Contexts/RootContext";
import { DataCollectorPhrases } from "~/helpers/phrases/data-collector";
import { callAPIClientSide } from "~/helpers/api";

interface FavoriteAlternativeProps extends DataCollectorChildProps {
  opinionData?: { id: number; opinion: VoteType };
  mainItemName?: string;
}
const FavoriteAlternative = (props: FavoriteAlternativeProps) => {
  const { item, mainItemName, opinionData } = props;

  const rootContext = useContext<RootContextValues>(RootContext);

  const sendData = () => {
    //setRequestInProcess(true);

    const postUrl = `/items/alternative/favorite/`;

    const returnData: DataCollectorReturnData = {
      type: "FavoriteAlternative",
      data: true,
      id: item.id,
    };

    callAPIClientSide(postUrl, null, "POST", rootContext.userId, opinionData.id)
      .then(() => {
        props.done(returnData);
        //setRequestInProcess(false);
      })
      .catch((err) => {
        rootContext.setError(
          "Error saving favorite " + "(" + err.message + ")"
        );
        props.done();
      });
  };

  return (
    <div data-testid="dc-favorite-alternative">
      <Heading element="h2">
        {DataCollectorPhrases.en.favoriteAlternative.header
          .replace(/{item.name}/g, item.name)
          .replace(/{mainItemName}/g, mainItemName)}
      </Heading>
      <p>{DataCollectorPhrases.en.favoriteAlternative.body}</p>
      <Spacer space={4} />
      <div className="buttons f-space-between">
        <div>
          <Button
            look="primary"
            fill={true}
            onClick={() => sendData()}
            aria-label="Yes"
          >
            YES
          </Button>
        </div>
        <div>
          <Button
            look="primary"
            fill={true}
            onClick={() => props.done()}
            aria-label="No"
          >
            NO
          </Button>
        </div>
      </div>
      <style jsx>{`
        .buttons > div {
          width: 49%;
        }
      `}</style>
    </div>
  );
};

export default FavoriteAlternative;
