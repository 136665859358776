import React from "react";
import Button from "~/components/Button";
import Heading from "~/components/Heading";
import Spacer from "~/components/Spacer";
import { DataCollectorPhrases } from "~/helpers/phrases/data-collector";

const AskToVerify = () => {
  return (
    <>
      <div data-testid="dc-asktoverify">
        <Heading element="h2">
          {DataCollectorPhrases.en.askToVerify.header}
        </Heading>
        <Spacer space={1} />
        <p>{DataCollectorPhrases.en.askToVerify.body}</p>
        <Spacer space={2} />
        <Button look="primary" fill={true} href="/user/edit/#account-settings">
          Go to user settings
        </Button>
        <Spacer space={2} />
      </div>
    </>
  );
};

export default AskToVerify;
