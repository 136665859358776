"use client";

import React, { CSSProperties } from "react";
import * as gtm from "~/helpers/analytics/gtm";

type ExternalLinkProps = {
  children: any;
  href: string;
  title?: string;
  rel?: string;
  style?: CSSProperties;
  className?: string;
  isAffiliate?: boolean;
  urlName?: string;
};

const ExternalLink = ({
  children,
  href,
  title,
  rel = "nofollow noopener",
  style = {},
  className,
  urlName,
}: ExternalLinkProps) => {
  let isAffiliate = false;

  if (href && href.indexOf("outgoing/software") > 0) {
    isAffiliate = true;
  }

  let gtmMethod = "Link";

  if (rel === "sponsored noopener") {
    gtmMethod = "SponsoredLink";
  }

  return (
    // im setting this via a prop.
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      style={style}
      className={className}
      translate="no"
      title={title}
      href={href}
      target="_blank"
      rel={rel}
      onClick={() => {
        if (urlName) {
          gtm.event("OutgoingClick", {
            method: gtmMethod,
            isAffiliate: isAffiliate,
            urlName: urlName,
          });
        }
      }}
    >
      {children}
    </a>
  );
};
export default ExternalLink;
