"use client";

import { sendGTMEvent } from "@next/third-parties/google";
import { usePathname, useSearchParams } from "next/navigation";

import { useEffect } from "react";
import { getPageGroups } from "~/helpers/gtmHelper";
import { getUrlEntityByUrl } from "~/helpers/urls";

interface GTMEventProps {
  isAdsenseBlocked?: boolean;
}

const GTMEvent = ({ isAdsenseBlocked = false }: GTMEventProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    let currentUrl = `${pathname}`;
    if (searchParams.toString() !== "") {
      currentUrl = currentUrl + "?" + searchParams.toString();
    }
    const pageTitle = document?.title ? document?.title : "Title is missing";
    const pageUrl = window?.location?.href;

    const urlEntity = getUrlEntityByUrl(currentUrl);

    const pageGroups = getPageGroups(urlEntity);

    const dataLayer = {
      ...pageGroups,
      page_title: pageTitle,
      page_location: pageUrl,
      a2_adsense_blocked: isAdsenseBlocked,
    };

    sendGTMEvent({ event: "virtualPageview", ...dataLayer });
  }, [pathname, searchParams, isAdsenseBlocked]);

  return null;
};
export default GTMEvent;
