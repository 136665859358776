"use client";

import { useState, useCallback } from "react";
import {
  WidgetTypes,
  DataCollectorReturnData,
  ISilentAction,
} from "~/c/DataCollector/types";

export interface DataCollectorWidget {
  itemId?: string;
  widgets: WidgetTypes[];
  silentAction?: ISilentAction;
  key?: string;
}

export function useDataCollector(itemId?: string) {
  const [renderDCWidget, setRenderDCWidget] =
    useState<DataCollectorWidget | null>(null);
  const [returnedData, setReturnedData] = useState<DataCollectorReturnData>();

  const showDataCollector = useCallback(
    (params: {
      widgets: WidgetTypes[];
      itemId?: string;
      silentAction?: ISilentAction;
      key?: string;
    }) => {
      setRenderDCWidget({
        itemId: params.itemId || itemId,
        widgets: params.widgets,
        silentAction: params.silentAction,
        key: params.key,
      });
    },
    [itemId],
  );

  const closeDataCollector = useCallback(() => {
    setRenderDCWidget(null);
  }, []);

  return {
    renderDCWidget,
    returnedData,
    showDataCollector,
    closeDataCollector,
    setReturnedData,
  };
}
