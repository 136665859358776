"use client";

import React, { useEffect, useState } from "react";
import { cn } from "@/lib/utils";

import styles from "./StarRating.module.scss";

interface StarProps {
  readOnly: boolean;
  value: number;
  rated: number;
  position: number;
  onMouseEnter?: any;
  onMouseLeave?: any;
  onClick?: any;
  tabIndex?: number;
}

function Star(props: StarProps) {
  const {
    readOnly,
    value,
    rated,
    onMouseEnter,
    onMouseLeave,
    onClick,
    tabIndex,
    position,
  } = props;

  return (
    <>
      <div
        className={cn(styles.star, {
          [styles.readOnly]: readOnly,
          [styles.semiActive]: value === 0,
          [styles.active]: position <= rated,
        })}
        onMouseEnter={onMouseEnter}
        onFocus={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onBlur={onMouseLeave}
        onClick={onClick}
        tabIndex={tabIndex}
        aria-label={readOnly ? null : `rate ${position} star`}
      >
        <i>★</i>
      </div>
    </>
  );
}

type StarRatingProps = {
  readOnly: boolean;
  initialRating?: number;
  onRated?: any;
};

const StarRating = (props: StarRatingProps) => {
  const { readOnly, initialRating, onRated } = props;

  const [stars, setStars] = useState(Array(5).fill(-1));
  const [rated, setRated] = useState(initialRating ? initialRating : 0);

  const handleMouseOver = (i: number) => {
    if (rated > 0) {
      const hoverRatedStars = stars.slice();
      hoverRatedStars.fill(0, rated, i);
      setStars(hoverRatedStars);
    } else {
      const hoverStars = Array(5).fill(-1);
      hoverStars.fill(0, 0, i + 1);
      setStars(hoverStars);
    }
  };

  const handleMouseOut = () => {
    if (rated > 0) {
      const resetRatedStars = stars.slice();
      resetRatedStars.fill(-1, rated, resetRatedStars.length);
      setStars(resetRatedStars);
    } else {
      const resetStars = stars.slice();
      resetStars.fill(-1, 0, resetStars.length);
      setStars(resetStars);
    }
  };

  const handleClick = (i) => {
    const clickedStar = stars.slice();

    clickedStar.fill(1, 0, i);
    clickedStar.fill(1, i, clickedStar.length);

    setStars(clickedStar);
    setRated(i);
    onRated(i);
  };

  useEffect(() => {
    setRated(initialRating);
  }, [initialRating]);

  const renderStar = (i: number) => {
    if (readOnly) {
      return (
        <Star readOnly={readOnly} position={i} value={stars[i]} rated={rated} />
      );
    } else {
      return (
        <Star
          position={i}
          value={stars[i]}
          rated={rated}
          tabIndex={i}
          readOnly={readOnly}
          onMouseEnter={() => handleMouseOver(i)}
          onMouseLeave={() => handleMouseOut()}
          onClick={() => handleClick(i)}
        />
      );
    }
  };

  return (
    <div
      className={cn(styles.ratingStars, {
        [styles.readOnly]: readOnly,
      })}
    >
      {renderStar(1)}
      {renderStar(2)}
      {renderStar(3)}
      {renderStar(4)}
      {renderStar(5)}
    </div>
  );
};

export default StarRating;
