import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "./BadgeRow.module.scss";

type BadgeRowProps = {
  children: any;
  showMoreStyle?: CSSProperties;
  lines?: number;
  showAllDefault?: boolean;
  enableShowMore?: boolean;
  lineHeight?: number;
  startOfFreeSpace?: number;
};

const BadgeRow = ({
  children,
  lines = 1,
  showAllDefault = false,
  enableShowMore = true,
  lineHeight = 30,
  startOfFreeSpace = 0,
}: BadgeRowProps) => {
  const [moreButtonVisible, setMoreButtonVisible] = useState(enableShowMore);
  const [showAllItems, setShowAllItems] = useState(showAllDefault);

  const contentRef = useRef<HTMLDivElement>();
  const moreButtonRef = useRef<HTMLLIElement>();

  useEffect(() => {
    setShowAllItems(showAllDefault);

    if (enableShowMore) {
      const realHeight = contentRef.current
        ? contentRef.current.getBoundingClientRect().height
        : lineHeight;

      // Add + 8 to compensate for various browsers
      if (realHeight > lines * lineHeight + 8) {
        setMoreButtonVisible(true);
      } else {
        setMoreButtonVisible(false);
      }
    }
  }, [showAllDefault, lines, lineHeight, enableShowMore, startOfFreeSpace]);

  const toggleShowMore = (showAllItems) => {
    setShowAllItems(showAllItems);
    setMoreButtonVisible(!showAllItems);
  };

  return (
    <>
      <div
        className={styles.badgeRow}
        style={{
          maxHeight: showAllItems ? "100%" : lines * lineHeight,
        }}
      >
        <div className={styles.content} ref={contentRef}>
          {children}
        </div>
        {moreButtonVisible && !showAllItems && enableShowMore && (
          <ul
            style={{ left: startOfFreeSpace }}
            className={`${styles.expand} badges`}
          >
            <li
              ref={moreButtonRef}
              onClick={() => {
                toggleShowMore(!showAllItems);
              }}
            >
              <span>...</span>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};
export default BadgeRow;
