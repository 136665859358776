export const DataCollectorPhrases = {
  en: {
    report: {
      textBoxPlaceholder:
        "After you've selected a report type, enter some more details here",
      header: "Report",
      body: "We aren't always right. What kind of problem do you want to inform us about?",
      selectPlaceholder: "What do you want to report?",
      selectChooseText: "Choose from these options",
      button: "Report",
    },
    pickPath: {
      body: "We need to know if you don't think it's a good alternative because it's not similar enough, or if you want to report something wrong with it, like if it's discontinued or duplicated.",
      notSimilarButton: "Not similar enough",
      notSimilarButtonSubText: "They don't share enough common functionality",
      otherButton: "Other reason",
      otherButtonSubText:
        "Duplicated, Discontinued, Dangerous, Wrong Data, etc.",
    },
    askToVerify: {
      header: "Verify your e-mail address",
      body: "You need to verify your e-mail address before you can do much on the site. Already verified? Try to logout and login again! Didn't get an e-mail or having any other issues?",
    },
    addToList: {
      header: "Add {item.name} to one of your lists?",
    },
    feature: {
      header: "Application info",
      mostImportant:
        "What do you feel are the most important aspects of {appName}?",
      textBoxPlaceHolder: "New feature or application type",
      addButton: "Add",
    },
    comment: {
      body: "Your thoughts help other people discover this software as a viable alternative.",
      body_news:
        "Since you had an opinion about this article, feel free to elaborate! Or close this window if you don't want to comment.",
      postComment: "Post comment",
      postReview: "Post review",
    },
    favoriteAlternative: {
      header:
        "Would you consider {item.name} the best alternative to {mainItemName}?",
      body: "For each list of alternatives, you can pick one as your absolute favorite. Are you sure this is your favorite from the list?",
    },
    appClassRating: {
      header: "How important is {item.name}?",
    },
  },
};
