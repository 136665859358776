import React, { Component, createRef, RefObject } from "react";
import FavoriteAlternative from "./FavoriteAlternative";
import Features from "./Features";
import AskToLogin from "./AskToLogin";
import AskToVerify from "./AskToVerify";
import PickPath from "./PickPath/PickPath";
import AppClassRating from "./AppClassRating/AppClassRating";
import Recaptcha from "./Recaptcha";
import Comment from "./Comment";
import End from "./End";
import {
  WidgetTypes,
  DataCollectionComponentMap,
  IDataCollectorProps,
  DataCollectorReturnData,
  ItemForDataCollector,
} from "./types";
import CommonModal from "~/components/CommonModal";
import { RootContext } from "~/components/Contexts/RootContext";
import { AuthUser } from "~/typings/types";
import SilentAction from "./SilentAction/SilentAction";
import Report from "./Report/Report";
import { callAPIClientSide } from "~/helpers/api";
import Heading from "~/c/Heading";
import Button from "~/c/Button";
import AddToList from "~/c/DataCollector/AddToList/AddToList";
//import * as gtag from "~/helpers/analytics/gtag";

interface Props extends IDataCollectorProps {
  /**
   * Called when data collection is closed or done
   */
  onClose?: () => any;
  /**
   * Called when data collection page changes
   */
  onChange?: (newHeight) => any;
}

interface State {
  /**
   * The widget that is currently displayed
   */
  currentWidget: number;
  /**
   * If the component is visible or not.
   */
  visible: boolean;
  /**
   * The data that's returned from the widgets' done() callback.
   * Stored in the form of:
   * {
   *    WidgetName: { ...data },
   *    AnotherWidget: { ...data}
   * }
   */
  receivedData: null | { [x: string]: any };
  /**
   * Data for the current app
   */
  item: ItemForDataCollector;
  /**
   * Whether the mobile pitch banner has been clicked or not
   */
  mobilePitchClicked: boolean;
  /**
   * Determines if all the data that the widget needs has been
   * fetched or not
   */
  requiredDataFetched: boolean;
  /**
   * An object that inform the parent if something needs to be done.
   */
  returnOnClose: any;
}

export default class DataCollector extends Component<Props, State> {
  state = {
    currentWidget: 0,
    visible: false,
    receivedData: {},
    item: null,
    mobilePitchClicked: false,
    requiredDataFetched: false,
    returnOnClose: {},
  };

  _isMounted = false;

  //componentDidUpdate() {}

  static defaultProps = {
    widgets: [],
    onClose: () => {},
    onChange: () => {},
  };

  // Load context
  declare context: React.ContextType<typeof RootContext>;
  static contextType = RootContext;

  /**
   * To track height
   */
  collectorRef: RefObject<HTMLDivElement> = createRef();

  /**
   * Define options
   */
  componentMapping: DataCollectionComponentMap = {
    None: { component: null, skippable: false, hideUI: false },
    FavoriteAlternative: {
      component: FavoriteAlternative,
      skippable: true,
      hideUI: false,
    },
    Features: {
      component: Features,
      skippable: true,
      hideUI: false,
    },
    AskToLogin: {
      component: AskToLogin,
      skippable: false,
      hideUI: false,
    },
    AskToVerify: {
      component: AskToVerify,
      skippable: false,
      hideUI: false,
    },
    PickPath: {
      component: PickPath,
      skippable: false,
      hideUI: false,
    },
    AppClassRating: {
      component: AppClassRating,
      skippable: false,
      hideUI: false,
    },
    AddToList: {
      component: AddToList,
      skippable: false,
      hideUI: false,
    },
    Comment: {
      component: Comment,
      skippable: true,
      hideUI: false,
    },
    Report: {
      component: Report,
      skippable: false,
      hideUI: false,
    },
    Recaptcha: {
      component: Recaptcha,
      skippable: false,
      hideUI: false,
    },
    SilentAction: {
      component: SilentAction,
      skippable: false,
      hideUI: true,
      props: {
        silentAction: this.props.silentAction,
      },
    },
    End: {
      component: End,
      skippable: false,
      hideUI: true,
      props: {
        silentAction: this.props.silentAction,
        done: () => {
          this.hide();
        },
      },
    },
  };

  abortController = new AbortController();

  /**
   * Fetch data and update the 'visible' state. This is used in the
   * render method to trigger the in/out transition
   */
  componentDidMount = () => {
    // This image is showed at the end, through the “End” component
    // We preload it here to prevent flicker

    // gtag.default.simple_event({
    //   action: "Start",
    //   category: "DataCollector",
    //   label: JSON.stringify(this.props.widgets),
    // });

    const img = new Image();
    img.src = "/static/a2.svg";

    if (this.props.itemId) {
      callAPIClientSide(
        `/items/${this.props.itemId}/`,
        null,
        "GET",
        this.context.userId,
      ).then((data) => {
        this.setState(
          {
            item: data,
            requiredDataFetched: true,
          },
          () => {
            // This is a little hack to make sure that the widget is rendered
            // once before we animate it in. That way we get the transition from
            // (hidden) -> (visible) and not just (visible) right away.
            setTimeout(() => {
              this.show();
            }, 10);
          },
        );
      });
    } else {
      const localWidgets = this.calculateWhichWidgetsToShow();

      const shouldShowDC = !(
        localWidgets &&
        localWidgets.length === 1 &&
        localWidgets[0] === "SilentAction"
      );

      if (shouldShowDC) {
        this.setState(
          {
            requiredDataFetched: true,
          },
          () => {
            // This is a little hack to make sure that the widget is rendered
            // once before we animate it in. That way we get the transition from
            // (hidden) -> (visible) and not just (visible) right away.
            setTimeout(() => {
              this.show();
            }, 10);
          },
        );
      }
    }

    this._isMounted = true;
  };

  componentWillUnmount() {
    this._isMounted = false;
    this.abortController.abort();
  }

  /**
   * Finish the data collector process
   */
  goToLast = () => {
    const widgets = this.calculateWhichWidgetsToShow();

    this.setState({
      currentWidget: widgets.length - 1,
    });

    this.goToWidget();
  };

  /**
   * Moves to the next widget
   */
  goToNext = (data?: { [x: string]: any }) => {
    const { currentWidget } = this.state;

    this.setState(
      {
        requiredDataFetched: false,
        currentWidget: currentWidget + 1,
      },
      () => {
        this.goToWidget(data);
      },
    );
  };

  /**
   * Moves to the widget that is the current
   */
  goToWidget = (data?: { [x: string]: any }) => {
    const { currentWidget } = this.state;
    const { onChange } = this.props;

    this.setState(
      {
        requiredDataFetched: true,
        receivedData: {
          ...this.state.receivedData,
          [currentWidget]: data,
        },
        returnOnClose: {},
      },
      () => {},
    );

    if (data && data.type) {
      if (this.props.returnData) {
        this.props.returnData(data as DataCollectorReturnData);
      }
    }

    // Call onChange when we go next
    setTimeout(
      () =>
        onChange(
          this.collectorRef.current && this.collectorRef.current.clientHeight,
        ),
      100,
    );
  };

  /**
   * Shows the data collection widget
   */
  show = () => {
    if (this._isMounted) {
      this.setState({
        visible: true,
      });
    }
  };

  /**
   * Hides the DataCollection component and triggers the
   * onClose callback. The parent component can then
   * safely unmount the component.
   */
  hide = () => {
    const { onClose } = this.props;

    if (this._isMounted) {
      this.setState({
        visible: false,
      });
    }

    // After some delay (to allow transitions to finish), we tell the
    // parent component that the data collection widget is done and
    // can be unmounted.
    // this._hideTimer = () =>
    //   setTimeout(() => {
    //     // return the timeoutID
    //     onClose();
    //   }, 500);
    setTimeout(() => onClose(), 500);
  };

  getSilentActionType = () => {
    const silentAction = this.props.silentAction;

    //console.log(silentAction, "silentAction");

    return silentAction && silentAction.type && silentAction.type;
  };

  /**
   * Triggered when the mobile “add more data” banner is clicked
   */
  setMobilePitchOpened = () => {
    this.setState({
      mobilePitchClicked: true,
    });

    const { onChange } = this.props;
    setTimeout(
      () =>
        onChange(
          this.collectorRef.current && this.collectorRef.current.clientHeight,
        ),
      100,
    );
  };

  /**
   * Retrieves the opinionId token if it exists. It will only
   * if one of the child components' done() handler has passed
   * that data back.
   */
  getOpinionData = () => {
    const { receivedData } = this.state;
    const { silentAction } = this.props;

    if (this.props.articleId) {
      return null;
    }

    let opinionData = Object.values(receivedData).find(
      (item: any) => item && item.id,
    ) as any;

    // console.log(opinionData, "opinionData");
    // console.log(silentAction, "silentAction");

    if (!opinionData && silentAction) {
      if (
        silentAction?.body?.voteType === "disagree" &&
        this.getSilentActionType() === "AlternativeVote"
      ) {
        opinionData = {
          id: silentAction?.body?.alternativeId,
          state: silentAction?.body?.voteType,
        };
      }
    }

    if (opinionData) {
      return { id: opinionData.id, opinion: opinionData.state };
    }
  };

  /**

   */
  getPickPathData = () => {
    const { receivedData } = this.state;

    const alternativeWidgets = Object.values(receivedData).find(
      (item: any) => item && item.widgets,
    ) as any;

    if (alternativeWidgets) {
      return { widgets: alternativeWidgets.widgets };
    }
  };

  /**
   * Returns and array with names of widgets that should be displayed.
   * This may vary depending on auth state and other circumstances.
   */
  calculateWhichWidgetsToShow = (): WidgetTypes[] => {
    let { widgets } = this.props;
    const { silentAction } = this.props;
    const user: AuthUser = this.context.user;
    const isLoggedIn = !!user;

    if (!isLoggedIn) {
      setTimeout(() => {
        if (!this.state.mobilePitchClicked) {
          this.setMobilePitchOpened();
        }
      }, 100);
    }

    const blockDiscussion =
      this.state.item &&
      this.state.item.metaTags &&
      this.state.item.metaTags.find(
        (metaTag) => metaTag.type === "Block Discussions",
      );

    if (blockDiscussion) widgets = widgets.filter((e) => e !== "Comment");

    const newPath = this.getPickPathData();

    if (!isLoggedIn) {
      if (this.getSilentActionType() === "Redirect") {
        widgets = ["AskToLogin", ...widgets];
      } else {
        widgets = ["AskToLogin", ...widgets, "End"];
      }
    } else {
      if (this.context.isInRole("freezer")) {
        widgets = [...widgets, "End"];
      } else {
        widgets = ["AskToVerify", "End"];
      }
    }

    // If the silent action is a "alternative vote
    if (
      silentAction?.body?.voteType === "disagree" &&
      this.getSilentActionType() === "AlternativeVote"
    ) {
      widgets = ["PickPath", ...widgets];
    }

    if (newPath?.widgets) {
      widgets = ["PickPath", ...newPath.widgets];
    }

    return widgets;
  };

  /**
   * Retrieves metadata about the widget that should currently be
   * rendered.
   */
  getCurrentWidget = () => {
    const { item, currentWidget } = this.state;
    const { onChange, mainItemName, silentAction, articleId } = this.props;
    const { componentMapping } = this;

    let widgetData = {};

    if (silentAction && silentAction.type === "Redirect") {
      widgetData = { redirectUrl: silentAction.url };
    }

    const childProps = {
      done: this.goToNext,
      finish: this.goToLast,
      item,
      opinionData: this.getOpinionData(), // Move to widgetData?
      mainItemName: mainItemName,
      data: widgetData,
      articleId: articleId,
    };

    const widgets = this.calculateWhichWidgetsToShow();
    const widgetName = widgets[currentWidget];
    const widget = componentMapping[widgetName];

    // A bit hacky. But needed to work in an iframe.
    if (widgetName === "Recaptcha") {
      setTimeout(() => onChange(500), 100);
    }

    // A bit hacky. But should hide after AskToLogin if a redirect silent action.
    if (
      widgetName === "AskToLogin" &&
      this.getSilentActionType() == "Redirect"
    ) {
      childProps.done = this.hide;
    }

    const Component = widget.component;

    const props = widget.props;

    return {
      widgetComponent: <Component {...childProps} {...props} />,
      skippable: widget.skippable,
      hideUI: widget.hideUI,
    };
  };

  render() {
    const { visible, requiredDataFetched, mobilePitchClicked } = this.state;
    const { onClose, itemId } = this.props;

    // Don't return anything if the required data has not yet been fetched
    if (itemId && !requiredDataFetched) {
      return null;
    }

    const { widgetComponent, skippable, hideUI } = this.getCurrentWidget();

    const mobileIntro = `Add some info about ${
      this.state.item ? this.state.item.name : "this"
    } and help other users.`;

    const dataCollectorHeader = this.state.item
      ? `About ${this.state.item.name}...`
      : `AlternativeTo asks ...`;

    return (
      <div
        ref={this.collectorRef}
        className={`data-collector ${visible ? "visible" : ""}`}
        data-testid="data-collector"
      >
        <>
          {hideUI && widgetComponent}
          {!hideUI && (
            <div className={mobilePitchClicked ? "mobile-pitch-clicked" : ""}>
              <div data-testid="mobile-pitch" className="mobile-pitch">
                <div>
                  <Heading element="h3">Help the community</Heading>
                  <p>{mobileIntro}</p>
                </div>
                <div>
                  <Button look="primary" onClick={this.setMobilePitchOpened}>
                    Start
                  </Button>
                  <span className="meta" onClick={() => onClose()}>
                    Close
                  </span>
                </div>
              </div>
              <CommonModal
                className={mobilePitchClicked ? "mobile-pitch-clicked" : ""}
                title={dataCollectorHeader}
                buttonElements={
                  <>
                    {skippable && (
                      <button
                        className="header-button"
                        onClick={() => this.goToNext()}
                      >
                        Skip
                      </button>
                    )}
                    <button
                      className="header-button close"
                      aria-label="Close"
                      data-testid="close-dc"
                      onClick={() => onClose()}
                    >
                      &times;
                    </button>
                  </>
                }
              >
                {widgetComponent}
              </CommonModal>
            </div>
          )}
        </>

        <style jsx>{`
          .data-collector {
            position: fixed;
            bottom: 40px;
            right: 40px;
            max-width: 400px;
            min-width: 400px;
            min-height: 200px;
            background: var(--mainBg);
            border-radius: 10px;
            box-shadow: 0 0 20px rgba(var(--mainFgRgb), 0.05);
            border: 1px solid var(--gray200);
            border-top: 1px solid var(--gray300);
            opacity: 0;
            transform: translate3d(50px, 0, 0);
            transition: all 0.3s ease-out;
            overflow: hidden;
            display: flex;
            z-index: 20;
          }
          .data-collector > div {
            width: 100%;
          }

          .mobile-pitch {
            display: none;
          }

          @media screen and (max-width: 600px) {
            .data-collector {
              transform: translate3d(0, 50px, 0);
              left: 0;
              right: 0;
              bottom: 0;
              max-width: 100%;
              min-height: 0;
              min-width: 0;
              border-radius: 0;
            }

            .mobile-pitch {
              padding: 10px 15px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .mobile-pitch div:first-child {
              padding-right: 20px;
            }

            .mobile-pitch span {
              display: flex;
              flex-direction: column;
              margin-top: 12px;
              align-items: center;
              font-size: 80%;
            }

            .mobile-pitch-clicked .mobile-pitch {
              display: none;
            }
          }
          @media screen and (width: 421px) {
            .data-collector {
              max-width: 400px;
              min-width: 400px;
              min-height: 200px;
              border-radius: 10px;
              left: 20px;
              bottom: 20px;
            }

            .mobile-pitch {
              display: none;
            }
          }
          .header-button {
            padding: 0.5em;
            min-width: 1em;
            line-height: 1;
            display: block;
            font-size: 1em;
            background: transparent;
            border: none;
            color: inherit;
            cursor: pointer;
          }
          .header-button.close {
            margin-right: -0.5em;
            font-weight: 500;
            font-size: 18px;
          }
          .data-collector.visible {
            transform: translate3d(0, 0, 0);
            opacity: 1;
          }
        `}</style>
      </div>
    );
  }
}
