import React from "react";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./RatingNumber.module.scss";

interface RatingNumberProps {
  titleText: string;
  number: number;
  onClick?: any;
  reverse?: boolean;
}

const RatingNumber = (props: RatingNumberProps) => {
  const { titleText, number, onClick, reverse = false } = props;

  let ratingClass = "rating-" + (number - 1);

  if (reverse) {
    ratingClass = "rating-reverse-" + (number - 1);
  }

  return (
    <div
      title={titleText}
      className={`${styles.circle} ${styles[ratingClass]}`}
      onClick={() => onClick && onClick("set-appclass-rating")}
    >
      <span>{number}</span>
    </div>
  );
};
export default RatingNumber;
