// export const GTM_ID = "GTM-N56PL75";

// export const pageview = (url, rest) => {
//   window.dataLayer?.push({
//     event: "pageview",
//     page: url,
//     ...rest,
//   });
// };

export const event = (eventName, rest) => {
  window.dataLayer?.push({
    event: eventName,
    ...rest,
  });
};
