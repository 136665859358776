"use client";

import Link from "next/link";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { GetAppTypeAndSuffix } from "~/helpers/appListHelper";
import { getUrl } from "~/helpers/urls";
import { AppType, Platform } from "~/typings/types";
import BadgeRow from "../BadgeRow";
import Spacer from "../Spacer";

type PlatformsNewLine = "always" | "never" | "auto";

type AppBadgeRowRowProps = {
  licenseModel?: string;
  licenseCost?: string;
  platforms?: Platform[];
  appTypes?: AppType[];
  platformRows: number;
  platformsOnNewLineOption?: PlatformsNewLine;
  enableShowMore?: boolean;
  lines?: number;
};

const AppBadgeRow = (props: AppBadgeRowRowProps) => {
  const {
    licenseCost,
    licenseModel,
    platforms,
    appTypes,
    platformRows,
    platformsOnNewLineOption = "auto",
    enableShowMore = true,
    lines = 1,
  } = props;

  const [startOfFreeSpace, setStartOfFreeSpace] = useState(0);
  const [startOfFreeSpacePlatforms, setStartOfFreeSpacePlatforms] = useState(0);

  const [platformsOnNewLine, setPlatformsOnNewLine] = useState(
    platformsOnNewLineOption === "always",
  );

  useEffect(() => {
    let newLine =
      platformsOnNewLineOption === "never" ? false : platformsOnNewLine;

    if (platforms && platformsOnNewLineOption === "auto") {
      const totalLength = platforms.length + (appTypes?.length || 0);
      newLine = totalLength >= 5 || appTypes?.length === 3;
      setPlatformsOnNewLine(newLine);
    }
  }, [platformsOnNewLineOption, platforms, appTypes, platformsOnNewLine]);

  const contentRef = useRef<HTMLUListElement | null>(null);
  const platformsLineRef = useRef<HTMLUListElement | null>(null);

  const calculateStartOfFreeSpace = useCallback(
    (ref: HTMLUListElement | null) => {
      if (!ref) return 0;

      const flexContainer = ref;
      let occupiedSpace = 0;

      const flexRect = flexContainer.getBoundingClientRect();

      Array.from(flexContainer.children).forEach((item) => {
        const rect = item.getBoundingClientRect();
        const isHidden = rect.y > flexRect.y + 20;

        if (!isHidden) {
          const style = window.getComputedStyle(item);
          occupiedSpace +=
            rect.width +
            parseFloat(style.marginLeft) +
            parseFloat(style.marginRight);
        }
      });

      return occupiedSpace;
    },
    [],
  );

  const updateSpaces = useCallback(() => {
    if (contentRef.current) {
      setStartOfFreeSpace(calculateStartOfFreeSpace(contentRef.current));
    }
    if (platformsLineRef.current) {
      setStartOfFreeSpacePlatforms(
        calculateStartOfFreeSpace(platformsLineRef.current),
      );
    }
  }, [calculateStartOfFreeSpace]);

  useEffect(() => {
    if (!enableShowMore) return;

    // Initial calculation
    updateSpaces();

    // Setup ResizeObserver
    const resizeObserver = new ResizeObserver(updateSpaces);

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }
    if (platformsLineRef.current) {
      resizeObserver.observe(platformsLineRef.current);
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [platformsOnNewLine, enableShowMore, appTypes, platforms]);

  return (
    <>
      <BadgeRow
        enableShowMore={enableShowMore}
        startOfFreeSpace={startOfFreeSpace}
        lines={lines}
      >
        <ul ref={contentRef} data-testid="lp-row" className="badges">
          {appTypes &&
            appTypes.map((appType, index) => {
              const nextUrl = appType.linkHref
                ? appType.linkHref
                : getUrl("feature", [appType.urlName]);
              return (
                <li className="badge-more-important" key={index}>
                  <Link prefetch={false} href={nextUrl}>
                    {GetAppTypeAndSuffix(appType.appType, appType.titleSuffix)}
                  </Link>
                </li>
              );
            })}
          {licenseCost && licenseModel && (
            <li
              className={`badge-license ${
                licenseCost && licenseCost.toLowerCase()
              }`}
            >
              <span>
                {licenseCost} • {licenseModel}
              </span>
            </li>
          )}

          {!platformsOnNewLine && (
            <>
              {platforms &&
                platforms.map((platform) => {
                  return (
                    <li key={platform.name}>
                      <span>{platform.name}</span>
                    </li>
                  );
                })}
            </>
          )}
        </ul>
      </BadgeRow>
      {platformsOnNewLine && platforms && (
        <>
          <Spacer space={1} />
          <BadgeRow
            enableShowMore={enableShowMore}
            lines={platformRows}
            startOfFreeSpace={startOfFreeSpacePlatforms}
          >
            <ul ref={platformsLineRef} data-testid="lp-row" className="badges">
              {platforms.map((platform) => {
                return (
                  <li key={platform.name}>
                    <span>{platform.name}</span>
                  </li>
                );
              })}
            </ul>
          </BadgeRow>
        </>
      )}
    </>
  );
};
export default AppBadgeRow;
