import React, { useContext, useEffect, useRef, useState } from "react";
import { DataCollectorChildProps } from "~/components/DataCollector/types";
import Heading from "~/components/Heading";
import Button from "~/components/Button";
import Spacer from "~/components/Spacer";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { RootContextValues } from "~/typings/types";
import { RootContext } from "~/c/Contexts/RootContext";

type Props = DataCollectorChildProps;

const AskToLogin = (props: Props) => {
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);

  let captchaSitekey = "0d1d0536-380b-4e37-ba3b-b7fcfa4122d3";

  const rootContext = useContext<RootContextValues>(RootContext);

  const DEPLOY_ENV = process.env.DEPLOY_ENV;

  if (
    process.env.NODE_ENV === "development" ||
    DEPLOY_ENV === "development" ||
    DEPLOY_ENV === "test" ||
    process.env.NODE_ENV === "test"
  ) {
    captchaSitekey = "10000000-ffff-ffff-ffff-000000000001";
  }

  const onMaybeLater = () => {
    captchaRef.current.execute();
  };

  useEffect(() => {
    if (token) {
      rootContext.setRecaptchaToken(token);
      props.done();
    }
  }, [token]);

  let registerUrl = "/api/auth/signup/";
  let loginUrl = "/api/auth/login/";
  let returnUrl = "";
  const returnToParam = "?returnTo=";

  if (props.data && props.data.redirectUrl) {
    returnUrl = returnToParam + encodeURIComponent(`${props.data.redirectUrl}`);
  } else {
    if (props.item) {
      returnUrl =
        returnToParam +
        encodeURIComponent(
          `${window.location.pathname}?action=like&target=${props.item.id}`
        );
    } else {
      returnUrl =
        returnToParam + encodeURIComponent(`${window.location.pathname}`);
    }
  }

  registerUrl += returnUrl;
  loginUrl += returnUrl;

  return (
    <div data-testid="dc-asktologin">
      <Heading element="h2">Register to save likes, comments and more</Heading>
      <Spacer space={1} />
      <p>Plus, you get to be a part of our amazing community :)</p>
      <Spacer space={4} />
      <div className="buttons f-space-between">
        <div>
          <Button
            nextLink={false}
            data-testid="register-button"
            look="primary"
            fill={true}
            href={registerUrl}
          >
            Register
          </Button>
        </div>
        <div>
          <Button
            fill={true}
            onClick={() => {
              onMaybeLater();
            }}
          >
            Maybe later
          </Button>
        </div>
      </div>
      <Spacer space={2} />
      <HCaptcha
        sitekey={captchaSitekey}
        size="invisible"
        onVerify={setToken}
        ref={captchaRef}
      />
      <div style={{ textAlign: "center" }}>
        <a className="meta" href={loginUrl}>
          Or sign in with existing account
        </a>
      </div>
      <style jsx>{`
        .buttons > div {
          width: 49%;
        }
      `}</style>
    </div>
  );
};

export default AskToLogin;
