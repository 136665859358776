"use client";

import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import SimpleShowMoreToggle from "./SimpleShowMoreToggle";

type MaxHeightWithFadeProps = {
  maxHeight?: number;
  fadeHeight?: string;
  children: any;
  toColor?: string;
  showMoreText?: string;
  extraShowMorePadding?: boolean;
};

const MaxHeightWithFade: FunctionComponent<MaxHeightWithFadeProps> = ({
  maxHeight = 116,
  fadeHeight = "85%",
  showMoreText,
  children,
}: MaxHeightWithFadeProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  const TOGGLE_BUTTON_HEIGHT = 26;
  const adjustedMaxHeight =
    hasOverflow && isMounted ? maxHeight - TOGGLE_BUTTON_HEIGHT : maxHeight;

  useEffect(() => {
    if (ref.current) {
      const contentHeight = ref.current.scrollHeight;
      setHasOverflow(contentHeight > maxHeight);
    }
  }, [maxHeight]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const containerStyle = {
    position: "relative" as const,
    ...(hasOverflow &&
      !isExpanded && {
        maxHeight: `${adjustedMaxHeight}px`,
        overflow: "hidden",
        maskImage: `linear-gradient(to bottom, black ${fadeHeight}, transparent 100%)`,
        WebkitMaskImage: `linear-gradient(to bottom, black ${fadeHeight}, transparent 100%)`,
      }),
    contain: "paint layout",
  };

  return (
    <>
      <div ref={ref} style={containerStyle}>
        {children}
      </div>

      {hasOverflow && showMoreText && !isExpanded && isMounted && (
        <>
          <SimpleShowMoreToggle
            totalItems={2}
            showingItems={1}
            enablePaging={false}
            text={showMoreText}
            addEmptyDivForCLS={false}
            onShowMore={() => setIsExpanded(true)}
          />
        </>
      )}
    </>
  );
};
export default MaxHeightWithFade;
