/* eslint-disable @next/next/no-img-element */
"use client";

import { useEffect, useState } from "react";
import Heading from "~/components/Heading";
import Spacer from "~/components/Spacer";
import { DataCollectorChildProps } from "~/components/DataCollector/types";
import { cn } from "~/lib/utils";

export function End({ done, opinionData }: DataCollectorChildProps) {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialized(true);
    }, 1);

    const doneTimer = setTimeout(() => {
      done?.();
    }, 1500);

    return () => {
      clearTimeout(timer);
      clearTimeout(doneTimer);
    };
  }, [done]);

  const text =
    opinionData?.opinion === "remove"
      ? "Your opinion is removed"
      : "Your knowledge helps other users find better software.";

  return (
    <div
      data-testid="dc-end"
      className={cn(
        "flex flex-1 items-center justify-center bg-[var(--popBrand)] p-[50px_25px] text-center text-white",
      )}
    >
      <div className="flex w-full flex-col items-center">
        <img
          className={cn(
            "mx-auto transition-all duration-250 ease-out",
            initialized
              ? "translate-y-0 opacity-100"
              : "translate-y-[30px] opacity-0",
          )}
          src="/static/a2.svg"
          alt="AlternativeTo's A2 robot"
        />
        <Spacer space={2} />
        <div
          className={cn(
            "transition-all duration-250 ease-out delay-300",
            initialized
              ? "translate-y-0 opacity-100"
              : "translate-y-[30px] opacity-0",
          )}
        >
          <Heading element="h3">Thanks!</Heading>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
}

export default End;
