import React, { useContext } from "react";
import { AdminPhrases } from "~/helpers/phrases/admin";
import { DataCollectorPhrases } from "~/helpers/phrases/data-collector";
import { RootContextValues } from "~/typings/types";
import RatingNumber from "~/components/Common/RatingNumber";
import { RootContext } from "~/components/Contexts/RootContext";
import Heading from "~/components/Heading";
import MaxHeightWithFade from "~/components/MaxHeightWithFade";
import Spacer from "~/components/Spacer";
import {
  DataCollectorChildProps,
  DataCollectorReturnData,
} from "~/components/DataCollector/types";
import { callAPIClientSide } from "~/helpers/api";

//interface AppClassRatingProps extends DataCollectorChildProps {}

const AppClassRating = (props: DataCollectorChildProps) => {
  const { item } = props;

  const rootContext = useContext<RootContextValues>(RootContext);

  const sendData = async (rating: number) => {
    const postUrl = `/items/${item.id}/set-appclass-rating/`;

    await callAPIClientSide(
      postUrl,
      null,
      "POST",
      rootContext.userId,
      rating,
    ).catch((err) => {
      rootContext.setError("Error setting app type " + "(" + err.message + ")");
      props.done();
    });

    const returnData: DataCollectorReturnData = {
      id: item.id,
      type: "AppClassRating",
      data: rating,
    };

    props.done(returnData);
  };

  const ratings = [];
  for (let i = 1; i < 11; i++) {
    // note: we are adding a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    ratings.push(
      <RatingNumber
        key={i}
        titleText={`Set ${i} as AppClass Rating`}
        onClick={() => sendData(i)}
        number={i}
      />,
    );
  }

  return (
    <div data-testid="dc-favorite-alternative">
      <Heading element="h2">
        {DataCollectorPhrases.en.appClassRating.header.replace(
          "{item.name}",
          item.name,
        )}
      </Heading>
      <Spacer space={4} />

      <div className="buttons f-space-between">{ratings}</div>
      <Spacer space={4} />
      <MaxHeightWithFade showMoreText="Show more">
        <span
          style={{ display: "flex", flexDirection: "column" }}
          className="meta"
        >
          <span>
            <strong>1-2</strong> {AdminPhrases.en.appClass.veryLowDesc}
          </span>
          <span>
            <strong>3-4</strong> {AdminPhrases.en.appClass.lowDesc}
          </span>
          <span>
            <strong>5-6</strong> {AdminPhrases.en.appClass.mediumDesc}
          </span>
          <span>
            <strong>7-8</strong> {AdminPhrases.en.appClass.highDesc}
          </span>
          <span>
            <strong>9-10</strong> {AdminPhrases.en.appClass.veryHighDesc}
          </span>
        </span>
      </MaxHeightWithFade>
    </div>
  );
};
export default AppClassRating;
