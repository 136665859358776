import Link from "next/link";
import React from "react";
import { FaArrowDown, FaEdit } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import AppIcon from "~/components/AppCommon/AppIcon";
import LazyImg from "~/components/LazyImg";
import { manualHtmlDecode, toUrlCompatible } from "~/helpers/stringHelpers";
import CopyDirectLink from "./CopyDirectLink";
import styles from "./RenderMarkdownWithLinks.module.scss";
import { replaceHost } from "~/helpers/urls";

type MdFormat = "md" | "md_Desc" | "md_Comments" | "md_News" | "md_Compact";

type RenderMarkdownWithLinksProps = {
  md: string;
  format?: MdFormat;
  disallowedElements?: string[];
  allowImages?: boolean;
  imageStyle?: React.CSSProperties;
  skipHtml?: boolean;
  addAltTextAsDescription?: boolean;
  linkTarget?: string;
  highlightHeader?: string;
  addAnchorLinksToHeaders?: boolean;
  addCopyLink?: boolean;
};

interface EditInfoProps {
  editor: string;
  date: string;
}

const EditInfo: React.FC<EditInfoProps> = ({ editor, date }) => (
  <span className="inline-flex items-center gap-1 text-sm text-meta float-right mt-[-20px]">
    <FaEdit className="h-3 w-3" />
    <span>Edited by {editor}</span>
    <span className="text-xs">•</span>
    <span>{date}</span>
  </span>
);

const RenderMarkdownWithLinks = (props: RenderMarkdownWithLinksProps) => {
  const {
    md,
    format,
    disallowedElements = ["code", "pre"],
    allowImages = false,
    imageStyle = {},
    addAltTextAsDescription = false,
    linkTarget,
    highlightHeader,
    addAnchorLinksToHeaders,
    addCopyLink,
    // skipHtml = true,
  } = props;

  const isServer = typeof window === "undefined";

  let pageIsTranslated = false;

  if (!isServer) {
    pageIsTranslated = !!document?.querySelector(
      "html.translated-ltr, head.translated-rtl, ya-tr-span, *[_msttexthash]",
    );

    if (pageIsTranslated) {
      if (typeof Node === "function" && Node.prototype) {
        const originalRemoveChild = Node.prototype.removeChild;
        Node.prototype.removeChild = function (child) {
          if (child.parentNode !== this) {
            if (console) {
              console.warn(
                "Swallowed 'Cannot remove a child from a different parent' error.",
              );
            }
            return child;
          }
          // eslint-disable-next-line prefer-rest-params
          return originalRemoveChild.apply(this, arguments);
        };

        const originalInsertBefore = Node.prototype.insertBefore;
        Node.prototype.insertBefore = function (newNode, referenceNode) {
          if (referenceNode && referenceNode.parentNode !== this) {
            if (console) {
              console.warn(
                "Swallowed 'Cannot insert before a reference node from a different parent' error.",
              );
            }
            return newNode;
          }
          // eslint-disable-next-line prefer-rest-params
          return originalInsertBefore.apply(this, arguments);
        };
      }
    }
  }

  const highlightComponent: any = {
    h2: ({ ...props }) => {
      const id = toUrlCompatible(props.children[0]);

      if (id === highlightHeader) {
        return (
          <h2 className={`${styles.mark} ${styles.highlightedHeader}`} id={id}>
            {props.children[0]} <FaArrowDown></FaArrowDown>
          </h2>
        );
      } else {
        return (
          <h2 className={styles.heading} id={id}>
            {props.children[0]}
            {addCopyLink && (
              <span className={`${styles.showOnHover} meta`}>
                <CopyDirectLink
                  url={`https://alternativeto.net/faq/#${id}`}
                  toastBody={`Copied a direct link to FAQ entry`}
                ></CopyDirectLink>
              </span>
            )}
          </h2>
        );
      }
    },
    h3: ({ ...props }) => {
      const id = toUrlCompatible(props.children[0]);

      if (id === highlightHeader) {
        return (
          <h3 className={styles.mark} id={id}>
            {props.children[0]}
          </h3>
        );
      } else {
        return (
          <h3 className={styles.heading} id={id}>
            {props.children[0]}
            {addCopyLink && (
              <span className={`${styles.showOnHover} meta`}>
                <CopyDirectLink
                  url={`https://alternativeto.net/faq/#${id}`}
                  toastBody={`Copied a direct link to FAQ entry`}
                ></CopyDirectLink>
              </span>
            )}
          </h3>
        );
      }
    },
  };

  let componentList: any = {
    a: ({ href, children }) => {
      if (href.indexOf("http") > -1) {
        return (
          <a
            translate="no"
            href={href}
            rel="noreferrer nofollow ugc"
            target="_blank"
          >
            {children[0]}
          </a>
        );
      } else if (href.startsWith("mailto:")) {
        return (
          <a translate="no" href={href}>
            {children[0]}
          </a>
        );
      } else {
        href = replaceHost(href, true);
        return (
          <Link
            translate="no"
            target={linkTarget && linkTarget}
            href={href as string}
          >
            {children[0]}
          </Link>
        );
      }
    },
    img: ({ ...props }) => {
      if (props.src.indexOf(":40:40") > -1) {
        return (
          <AppIcon
            appName={props.alt}
            dimension={14}
            plainUrl={props.src}
            style={{ position: "relative", top: "-2px" }}
            className="rounded-full !inline-flex w-[14px] h-[14px]"
          ></AppIcon>
        );
      } else {
        if (allowImages) {
          return (
            <>
              <LazyImg
                innerStyle={{ ...imageStyle }}
                src={props.src}
                alt={props.alt}
              ></LazyImg>
              {addAltTextAsDescription && (
                <span
                  style={{
                    margin: "0 12px 12px 12px",
                    textAlign: "center",
                    fontSize: "70%",
                    display: "block",
                  }}
                  className="meta"
                >
                  {props.alt}
                </span>
              )}
            </>
          );
        } else {
          return null;
        }
      }
    },
    p: ({ children }) => {
      if (
        typeof children[0] === "string" &&
        children[0].match(/\[Edited by .+?\]/)
      ) {
        const match = children[0].match(/\[Edited by (.+?), (.+?)\]/);
        if (match) {
          const [fullMatch, editor, date] = match;
          const remainingText = children[0].replace(fullMatch, "").trim();

          return (
            <>
              {remainingText}
              <EditInfo editor={editor} date={date} />
            </>
          );
        }
      }
      return <p>{children}</p>;
    },
  };

  if (addAnchorLinksToHeaders) {
    componentList = { ...componentList, ...highlightComponent };
  }

  return (
    <>
      <ReactMarkdown
        disallowedElements={disallowedElements}
        unwrapDisallowed={true}
        //skipHtml={skipHtml}
        className={format ? format : null}
        components={componentList}
      >
        {manualHtmlDecode(md)}
      </ReactMarkdown>
    </>
  );
};

export default RenderMarkdownWithLinks;
