"use client";

import { DataCollectorWidget } from "./hooks/useDataCollector";
import DataCollector from "~/components/DataCollector";

interface DataCollectorWrapperProps {
  renderDCWidget: DataCollectorWidget | null;
  mainItemName: string;
  onClose: () => void;
  onReturn?: (data: any) => void;
}

export function DataCollectorWrapper({
  renderDCWidget,
  mainItemName,
  onClose,
  onReturn,
}: DataCollectorWrapperProps) {
  if (!renderDCWidget) return null;

  return (
    <DataCollector
      key={renderDCWidget.key}
      itemId={renderDCWidget.itemId}
      mainItemName={mainItemName}
      widgets={renderDCWidget.widgets}
      silentAction={renderDCWidget.silentAction}
      onClose={onClose}
      returnData={(data) => {
        if (onReturn) {
          onReturn(data);
        }
        //onClose();
      }}
    />
  );
}
